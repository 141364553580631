
function stringIsEmptyOrNull(str: string) {
    if (str == null) {
        return true;
    }
    str = str + '';
    return (str.length === 0 || str.match(/^ *$/) != null);
}

// https://stackoverflow.com/questions/2998784/how-to-output-numbers-with-leading-zeros-in-javascript
function getPaddedString(num: number, noOfPlaces: number) {
    let s = num + "";
    while (s.length < noOfPlaces) {
        s = "0" + s;
    }
    return s;
}

function getDisplayDuration(durationSecs: number, showHours: boolean = true) {
    const hours = Math.floor(durationSecs / 3600);
    const h = getPaddedString(hours, 2);
    let minutes = Math.floor((durationSecs % 3600) / 60);
    if (!showHours) {
        minutes += (hours * 60);
    }
    const m = getPaddedString(minutes, 2);
    const s = getPaddedString(Math.floor(durationSecs % 3600) % 60, 2);
    if (showHours) {
        return h + ":" + m + ":" + s;
    } else {
        return m + ":" + s;
    }
}

function getDisplayTitle(titleMain: string, titleSecondary: string) {
    let title = titleMain;
    if (!stringIsEmptyOrNull(titleSecondary)) {
        title += " (" + titleSecondary + ")";
    }
    return title;
}
/*
return TRUE if the elements in str are in otherStr, OR if str is empty/null
example: otherStr is "sally robinson"
str contains "sa ins". in this case true is returned.
allows for search using partial matches based on substrings separated by spaces.
*/
function stringIsInOtherString(str: string, otherStr: string) {
    if (stringIsEmptyOrNull(str)) {
        return true;
    }
    str = str.toLowerCase();
    otherStr = otherStr.toLowerCase();
    let subStrings = str.split(" ");
    subStrings = subStrings.filter(s => !stringIsEmptyOrNull(s));
    let match = true;
    subStrings.forEach((s) => {
        match = match && otherStr.includes(s);
    })
    return match;
}

// take some kind of param name and return something suitbable for an html id.
// https://stackoverflow.com/questions/1983648/replace-spaces-with-dashes-and-make-all-letters-lower-case
function replaceSpacesInString(str: string, replaceWith: string = '-') {
    return str.replace(/\s+/g, replaceWith);
}

function bindNameArray(nameArray: Array<string>, bindTo: any) {
    nameArray.forEach(methodName => {
        bindTo[methodName] = bindTo[methodName].bind(bindTo);
    })
}

export { stringIsEmptyOrNull, getPaddedString, getDisplayDuration, getDisplayTitle, stringIsInOtherString, replaceSpacesInString, bindNameArray }
    
    import React from 'react';

    import BandcampEmbed from '../embed/BandcampEmbed.tsx';
    import SoundCloudEmbed from '../embed/SoundCloudEmbed.tsx';
    import YouTubeEmbed from '../embed/YouTubeEmbed.tsx';
    import SpotifyEmbed from '../embed/SpotifyEmbed.tsx';
    import AppleMusicEmbed from '../embed/AppleMusicEmbed.tsx';

    // source: https://gist.github.com/takien/4077195
    function getYouTubeParams(embedCode) {
        try {
            var urlArray = embedCode.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            return {
                netHostName: 'youtube',
                videoCode: (urlArray[2] !== undefined) ? urlArray[2].split(/[^0-9a-z_\-]/i)[0] : urlArray[0]
            };
        } catch (e) {
            return {
                error: 'could not parse youtube code'
            }
        }
    }

    function getSoundCloudParams(embedCode) {
        try {
            return {
                netHostName: 'soundcloud',
                trackCode: embedCode.split('/tracks/')[1].split('&')[0]
            }
        } catch (e) {
            return {
                error: 'could not parse soundcloud code'
            }
        }
    }

    function getAppleParams(embedCode) {
        let srcString = '';
        try {
            srcString = embedCode.split('src="')[1];
            srcString = srcString.split('"><iframe>')[0];
            // console.log('parsing apple params, src string is ', srcString);
            return { netHostName: 'apple', srcString };
        } catch (e) {
            return {
                error: 'could not parse apple code'
            }
        }
    }

    function getBandcampParams(embedCode) {
        try {
            const albumId = embedCode.split('/album=')[1].split('/size=')[0];
            const trackLinkId = embedCode.split('/album=')[1].split('/size=')[1].split('/track=')[1].split('/transparent')[0];
            const href = embedCode.split('href="')[1].split('"')[0];
            const title = embedCode.split('href="')[1].split('"')[1].split('>')[1].split('</')[0];
            const embedBandcamp = {
                netHostName: 'bandcamp',
                albumId, trackLinkId, href, title
            };
            return embedBandcamp;
        } catch (e) {
            return {
                error: 'could not parse bandcamp code'
            }
        }
    }
    //https://open.spotify.com/track/3IT2SBLVlYxPEJzvGc1wom
    function getSpotifyParams(embedCode) {
        try {
            // be tolerant to either just the album code being entered (3IT2SBLVlYxPEJzvGc1wom) or the whole URL (https://open.spotify.com/track/3IT2SBLVlYxPEJzvGc1wom)
            const splitInfo = embedCode.split('/track/');
            let albumCode = splitInfo[0];
            if (splitInfo.length === 2) {
                albumCode = splitInfo[1];
            };
            const embedSpotify = {
                netHostName: 'spotify',
                albumCode
            };
            return embedSpotify;
        } catch (e) {
            return {
                error: 'could not parse spotify code'
            }
        }
    }

    function getTrackEmbedParams(netHostName, embedCode) {
        let params = {};
        // console.log('getTrackEmbedParams', netHostName.toLowerCase())
        switch (netHostName.toLowerCase()) {
            case 'youtube':
                params = getYouTubeParams(embedCode);
                break;
            case 'soundcloud':
                params = getSoundCloudParams(embedCode);
                break;
            case 'bandcamp':
                params = getBandcampParams(embedCode);
                break;
            case 'spotify':
                params = getSpotifyParams(embedCode);
                break;
            case 'apple':
                params  = getAppleParams(embedCode);
                break;
            default: 
                params = {
                    error: 'net host not found'
                };
                break;
        }
        return params;
    }

    function getRenderForEmbeddedInfo(embeddedInfo) {
        let embeddedInfoJson = {};
        try {
            embeddedInfoJson = JSON.parse(embeddedInfo);
        } catch (e) {
            return (<div>Error: could not parse embedded info!</div>)
        }
        const netHostName = embeddedInfoJson.netHostName;
        let render = (<div>Error: could not detect host name!</div>);
        switch (netHostName.toLowerCase()) {
            case 'youtube':
                render = (<YouTubeEmbed key={embeddedInfoJson.videoCode} videoId={embeddedInfoJson.videoCode}/>);
                break;
            case 'soundcloud':
                render = (<SoundCloudEmbed key={embeddedInfoJson.trackCode} trackId={embeddedInfoJson.trackCode}/>);
                break;
            case 'bandcamp': 
                render = (<BandcampEmbed key={embeddedInfoJson.trackLinkId} {...embeddedInfoJson}/>);
                break;
            case 'spotify':
                render = (<SpotifyEmbed key={embeddedInfoJson.albumCode} albumCode={embeddedInfoJson.albumCode} />);
                break;
            case 'apple':
                // console.log('trying to understand apple embed... json is', embeddedInfoJson);
                render = (<AppleMusicEmbed key={embeddedInfoJson.srcString} srcString={embeddedInfoJson.srcString} />);
                break;
            default: 
                break;
        }
        return render;
    }

    export { getTrackEmbedParams, getRenderForEmbeddedInfo }
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../css/flex.css';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

const useStyles = makeStyles({
    embedWrapper: {
        // width: '600px',
        height: '140px',
        backgroundColor: '#F8F8FA',
        borderRadius: '4px'
    }

});

interface SpotifyEmbedProps {
    albumCode: string;
}

export default function SpotifyEmbed(props: SpotifyEmbedProps) {

    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const [iframeWidth, setIframeWidth] = useState<number>(300);

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width]);

    function calcLayout() {
        respondToParentWidth('spotify-embed-wrapper', (width: number) => {
            setIframeWidth(width * 0.9);
        });
    }
    
    const iframeTitle: string = 'spotify-embed_' + props.albumCode;
    const srcString: string = 'https://open.spotify.com/embed/track/' + props.albumCode;

    return (<div id="spotify-embed-wrapper" className={classes.embedWrapper + " flex-row-center-center"}>
        <iframe title={iframeTitle} width={iframeWidth} height="80" frameBorder="0" allow="encrypted-media" src={srcString}>
        </iframe>
    </div>);

}

import React, { useState, useEffect } from 'react';
import { Release, ReleaseGroupOnNetHost, NetHost } from '../dtos/dto';
import { getReleaseGroupOnNetHostsForRelease } from '../api/api_release';
import { fetchNetHosts } from '../api/api_nethost';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReleaseNameDisplayComponent from './ReleaseNameDisplay';
import '../css/generic.css';
import '../css/flex.css';
import { getReleaseImageSrcForCatNo } from '../util/track.util';

const useStyles = makeStyles({
    tracksOnReleaseText: {
        margin: '5px 20px',
    },
    releaseImg: {
        width: '80px'
    },
    linkImg: {
        width: '60px',
        marginRight: '30px'
    },
    linksWrapper: {
        marginTop: '30px',
        marginLeft: '20px'
    },
    linkWrapper: {
        marginBottom: '20px'
    },
    linkText: {
        fontSize: '1.1em'
    },
    allWrapper: {
        height: '400px'
    },
    mainWrapper: {
        // backgroundColor: 'red'
    },
    doneButtonWrapper: {
        // backgroundColor: 'blue',
        width: '100%'
    }
});

interface ReleaseGroupsOnNetHostProps {
    release: Release;
    netHostArray: Array<NetHost>;
    releaseGroupOnNetHostArray: Array<ReleaseGroupOnNetHost>;
    onClose: () => any;
}

export default function ReleaseGroupsOnNetHostComponent(props: ReleaseGroupsOnNetHostProps) {
    const classes = useStyles();
    // const [netHostArray, setNetHostArray] = useState<Array<NetHost>>([]);
    //const [releaseGroupOnNetHostArray, setReleaseGroupOnNetHostArray] = useState<Array<ReleaseGroupOnNetHost>>([]);

    // const releaseGroupOnNetHostSortFunc = (a: ReleaseGroupOnNetHost, b: ReleaseGroupOnNetHost) => {
    //     if (getNetHostNameForId(a.netHostId) < getNetHostNameForId(b.netHostId)) {
    //         return -1;
    //     }
    //     if (getNetHostNameForId(a.netHostId) > getNetHostNameForId(b.netHostId)) {
    //         return 1;
    //     }
    //     return 0;
    // }

    // useEffect(() => {
    //     fetchNetHosts((_netHostArray: Array<NetHost>) => {
    //         setNetHostArray(_netHostArray);
    //     });

    // }, [props.release]);

    // useEffect(() => {
    //     getReleaseGroupOnNetHostsForRelease(props.release.id, (result: Array<ReleaseGroupOnNetHost>) => {
    //         setReleaseGroupOnNetHostArray(result.sort(releaseGroupOnNetHostSortFunc));
    //     });
    // }, [props.netHostArray])

    function getNetHostNameForId(netHostId: number): string {
        const netHost: NetHost | undefined = props.netHostArray.find((netHost: NetHost) => netHost.id === netHostId);
        if (netHost != null) {
            return netHost.name;
        } else {
            return 'unknown'
        };
    }
    function getImgSrcForNetHostWithId(netHostId: number): string {
        const netHost: NetHost | undefined = props.netHostArray.find((netHost: NetHost) => netHost.id === netHostId);
        if (netHost != null) {
            return process.env.PUBLIC_URL + 'images/nethosts/' + netHost.iconFileName + '.jpg';
        } else {
            return 'unknown'
        };
    }

    const imgSrc = getReleaseImageSrcForCatNo(props.release.catNo);

    return (<div className="material-font-family">
        <div className={classes.allWrapper + " flex-column-space-between-start"}>
            <div className={classes.mainWrapper}>
                <div className="flex-row-start-center">
                    <div>
                        <img className={classes.releaseImg + " release-image-small"} src={imgSrc}></img>
                    </div>
                    <div className="flex-column-start-start">
                        <div className={classes.tracksOnReleaseText}>Store Links for release</div>
                        <div className={classes.tracksOnReleaseText}>
                            <ReleaseNameDisplayComponent release={props.release} includeVersionDescription={false}/>
                        </div>
                    </div>
                </div>
                <div className={classes.linksWrapper}>
                    {props.releaseGroupOnNetHostArray.map((releaseGroupOnNetHost: ReleaseGroupOnNetHost, index: number) => {
                        const linkImgSrc: string = getImgSrcForNetHostWithId(releaseGroupOnNetHost.netHostId)
                        return (<div key={index} className={classes.linkWrapper + " flex-row-start-center"}>
                            <a href={releaseGroupOnNetHost.linkAddr} target="_blank"><img className={classes.linkImg + " release-image-small"} src={linkImgSrc}></img></a>
                            <div className={classes.linkText}>
                                <a href={releaseGroupOnNetHost.linkAddr} target="_blank">{getNetHostNameForId(releaseGroupOnNetHost.netHostId)}</a>
                            </div>

                        </div>);
                    })}
                </div>
            </div>
            <div className={classes.doneButtonWrapper + " flex-row-end-center"}>
                <Button variant="outlined" color="secondary" size="small" onClick={props.onClose}><strong>Done</strong></Button>
            </div>
        </div>
    </div>)
}
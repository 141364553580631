import { Base } from './base.dto';

export class NetHost extends Base {
    name: string = '';
    baseAddr: string = '';
    iconFileName: string = '';

    static createFromDto(object: any): NetHost {
        const instance = new NetHost();
        Object.assign(instance, super.createFromDto(object));
        instance.name = object.name;
        instance.baseAddr = object.baseAddr;
        instance.iconFileName = object.iconFileName ?? '';
        return instance;
    }
}

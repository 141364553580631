export class Base {
    id: number = -1;

    static createFromDto(object: any): Base {
        const instance = new Base();
        instance.id = object.id;
        return instance;
    }

    public toDto(): Object {
        return {
            id: this.id
        }
    }

}

import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

interface ChipGroupProps {
    chipNames: Array<string>;
    canDelete: boolean;
    canClick: boolean;
    chipDeletedAtIndex?: (index: number) => any;
    chipNamesUpdated?: (chipNames: Array<string>) => any;
    chipNameWasClicked?: (chipName: string) => any;
}

const useStyles = makeStyles({
    chip: {
        marginRight: '5px',
        marginBottom: '5px'
    },
});

export default function ChipGroupComponent(props: ChipGroupProps) {

    const classes: any = useStyles();

    function handleDelete(index: number) {
        removeChipAtIndex(index);
    }
    function removeChipAtIndex(index: number) {
        if (props.chipDeletedAtIndex != null) {
            props.chipDeletedAtIndex(index);
            const chipNames = [...props.chipNames];        // copy array
            chipNames.splice(index, 1);
            if (props.chipNamesUpdated != null) {
                props.chipNamesUpdated(chipNames);
            };
        };
    }

    function handleChipClicked(index: number) {
        if (props.chipNameWasClicked != null) {
            props.chipNameWasClicked(props.chipNames[index]);
        };
    }

    return (<div>
        {props.chipNames.map((chipName, index) => {

            return (
                <Chip className={classes.chip}
                key={index}
                label={chipName}
                onClick={props.canClick ? () => handleChipClicked(index) : undefined}
                onDelete={props.canDelete ? () => handleDelete(index) : undefined} />
            );
        })}
    </div>);
    
}

import React, { useState, useEffect } from 'react';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

interface BandcampEmbedProps {
    albumId: string;
    trackLinkId: string;
    href: string;
    title: string;
}
export default function BandcampEmbed(props: BandcampEmbedProps) {

    const { height, width } = useWindowDimensions();
    const [embedWidth, setEmbedWidth] = useState<number>(300);
    const [srcString, setSrcString] = useState<string>('');

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width]);

    //const srcString: string = 'https://bandcamp.com/EmbeddedPlayer/album=' + props.albumId + '/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=' + props.trackLinkId + '/transparent=true/';
    const iframeTitle: string = 'bandcamp-embed_' + props.title + '_' + props.trackLinkId;
 
    function calcLayout() {
        respondToParentWidth('bandcamp-embed-wrapper', (width: number) => {
            setEmbedWidth(width - 20);
            const _srcString = 'https://bandcamp.com/EmbeddedPlayer/album=' + props.albumId + '/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false' + 
            (width >= 400 ? '/artwork=small' : '')
            + '/track=' + props.trackLinkId + '/transparent=true/';
            setSrcString(_srcString);
        });
    }

    // todo: use usestyle
    const iframeStyle = {
        border: '0',
        width: embedWidth >= 400 ? embedWidth : '260px',
        height: embedWidth >= 400 ? '140px' : '400px'
    };
    return (<div id="bandcamp-embed-wrapper">
            <iframe title={iframeTitle} style={iframeStyle} src={srcString} seamless><a href={props.href}>{props.title}</a></iframe>
    </div>);
    
}
import { Base } from './base.dto';
import { TrackOverview } from './trackoverview.dto';

export class TrackDetail extends Base {
    artistId: number = -1;
    trackOverview = new TrackOverview();
    remixOfTrackId: number = -1;
    remixArtistId: number = -1;
    bpm: number = 0;
    artistIdSecondary: number = -1;

    static createFromDto(dto: any): TrackDetail {
        const instance = new TrackDetail();
        Object.assign(instance, super.createFromDto(dto));
        instance.artistId = dto.artistId;
        instance.trackOverview = TrackOverview.createFromDto(dto.trackOverview);
        instance.remixOfTrackId = dto.remixOfTrackId;
        instance.remixArtistId = dto.remixArtistId;
        instance.bpm = dto.bpm;
        instance.artistIdSecondary = dto.artistIdSecondary;
        return instance;
    }
}
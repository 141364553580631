import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Column, Table } from 'react-virtualized';
import { defaultRowRenderer } from "react-virtualized/dist/es/Table";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Keyword, TrackOverview } from '../dtos/dto';

import { stringIsEmptyOrNull, stringIsInOtherString, bindNameArray } from '../util/utilities';
import { fetchKeywords, getKeywordIdForName } from '../api/api_keyword';
import { sortTrackOverviews } from '../util/track.util';
// import useWindowDimensions from '../util/windowDimensions';

import 'react-virtualized/styles.css'; // only needs to be imported once
import '../css/TrackTable.css';
import '../css/generic.css';
import '../css/flex.css';

// const useStyles = makeStyles({
//     loadingWaitWrapper: {
//         width: '600px',
//         height: '500px'
//     },
//     loadingText: {
//         marginLeft: '50px'
//     }

// });

interface TableDisplayParams {
    width: number;
    height: number;
    showArtist: boolean;
    showTime: boolean;
    showBpm: boolean;
    artistWidth: number;
    titleWidth: number;
    timeWidth: number;
    bpmWidth: number;
}

interface TrackTableProps {
    trackOverviews: Array<TrackOverview>;
    // filterParams: FilterParams;
    filterText: string;
    keywords: Array<string>;
    trackUpdateCount: number;  // increase to force filter to be reapplied
    selectedTrackId: number | null;
    uiChangeCount: number;      // increase to force resize of table after mode switched
    tableWidth: number;
    tableHeight: number;
    trackSelected: (trackId: number) => any;
}
type SortDirection = "ASC" | "DESC" | undefined;

export default function TrackTable(props: TrackTableProps) {
    // const classes = useStyles();
    // const { height, width } = useWindowDimensions();
    let tableRef: any;

    const [keywordIds, setKeywordIds] = useState(new Array<number>());
    const [filteredResults, setFilteredResults] = useState(new Array<TrackOverview>());
    const [sortedResults, setSortedResults] = useState(new Array<TrackOverview>());
    const [sortBy, setSortBy] = useState("displayTitle");
    const defaultSortDirection: SortDirection = "DESC";
    const [sortDirection, setSortDirection] = useState<SortDirection>(defaultSortDirection); 
    //const [lastSelectedRowId, setLastSelectedRowId] = useState(-1); 
    const [updateTableCount, setUpdateTableCount] = useState(0);
    const [scrollToIndex, setScrollToIndex] = useState(0);

    const defaultTrackIdToTableRowIndexDict: {[trackId: number]: number} = { };
    const [trackIdToTableRowIndexDict, setTrackIdToTableRowIndexDict] = useState(defaultTrackIdToTableRowIndexDict);
    const [tableDisplayParams, setTableDisplayParams] = useState<TableDisplayParams>({width: 0, height: 0, showArtist: false, showTime: false, showBpm: false, artistWidth: 0, titleWidth: 0, timeWidth: 0, bpmWidth: 0});
    const [showHeader, setShowHeader] = useState<boolean>(true);

    useEffect(() => {
        // recalculate tableDisplayParams
        const p = {...tableDisplayParams};
        p.width = props.tableWidth;
        //width * (width >= 768 ? 0.5 : 1);
        p.height = props.tableHeight;
        //height - 100;
        p.showArtist = p.width > 450;
        p.showTime = p.width > 540;
        p.showBpm = p.width > 600;
        // assign the widths as previously (when table was fixed width),
        // calculate the total,
        // then use these to calculate fractions of the actual table width
        p.artistWidth = p.showArtist ? 140 : 0;
        p.titleWidth = 330;
        p.timeWidth = p.showTime ? 90 : 0;
        p.bpmWidth = p.showBpm ? 80 : 0;
        const totalWidth = p.artistWidth + p.titleWidth;
        p.artistWidth = (p.artistWidth / totalWidth) * p.width;
        p.titleWidth = (p.titleWidth / totalWidth) * p.width;
        // keep fixed time and bpm widths
        setTableDisplayParams(p);

        if (!p.showArtist && !p.showTime && !p.showBpm) {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }

        // const trackBrowserElement = document.getElementById('track-browser');
        // if (trackBrowserElement != null) {
        //     const boundingRect = trackBrowserElement.getBoundingClientRect();
        //     const top: number = boundingRect.top;
        //     const tableHeight = height - top;
        //     p.height = tableHeight - 30;
        // };
        // const trackBrowserOptionalUI = document.getElementById('track-browser-optional-ui');
        // if (trackBrowserOptionalUI != null) {
        //     const boundingRect = trackBrowserOptionalUI.getBoundingClientRect();
        //     p.height -= boundingRect.height;
        // }
    }, [props.tableWidth, props.tableHeight, props.uiChangeCount]);

    useEffect(() => {
        filterResults();
        
        // although nothing happens in response, if this isn't called then keywords don't appear in track detail.. hmm...
        fetchKeywords((keywords: any) => {
            //console.log('fetched keywords, they are ', keywords);
        })
    }, []);

    useEffect(() => {
        filterResults();
    }, [props.filterText])

    useEffect(() => {
        // console.log('useEffect props.selectedTrackId: ', props.selectedTrackId, trackIdToTableRowIndexDict, trackIdToTableRowIndexDict[props.selectedTrackId ?? 0]);
        setScrollToIndex(trackIdToTableRowIndexDict[props.selectedTrackId ?? 0]);
        //setLastSelectedRowId(props.selectedTrackId ?? -1);
    }, [props.selectedTrackId])

    useEffect(() => {
        // console.log('keyword ids is', keywordIds)
        filterResults();
        //setScrollToIndex(trackIdToTableRowIndexDict[100]);
        // setSortBy('title');
        // setSortDirection('DESC');
        // sortResults();
        
    }, [props.trackOverviews, props.trackUpdateCount, keywordIds]);

    useEffect(() => {
        // console.log('filter params changed: ', props.filterParams);
        const _keywordIds = new Array<number>();
        props.keywords.forEach((keyword: string) => {
            const keywordId: number = getKeywordIdForName(keyword);
            // console.log('keyword id is ', keywordId);
            if (keywordId !== -1) {
                _keywordIds.push(keywordId);
            };
            // console.log('track table keywords.. ', props.keywords, _keywordIds);
        });
        setKeywordIds(_keywordIds);
    }, [props.keywords]);

    useEffect(() => {
        sortResults();
        // tableRef?.forceUpdateGrid();
    }, [filteredResults, sortBy, sortDirection]);

    useEffect(() => {
        tableRef?.forceUpdateGrid();
        // console.log('just tried to force update grid, tableRef is ', tableRef);
    }, [props.trackUpdateCount, updateTableCount]);

    // @param: an array with the keyword ids for a track.
    // returns true if these match the ids to search for or false if not.
    // a match happens if all of this.state.keywordIds appear in the keywordIds argument array

    const keywordsDoMatch = (trackKeywordIds: Array<number>): boolean => {
        // if any of the entered keywords do not exist in the database, a match cannot be valid.
        if (keywordIds.indexOf(-1) !== -1) {
            return false;
        }
        for (let i = 0; i < keywordIds.length; i++) {
            const thisId = keywordIds[i];
            if (trackKeywordIds.indexOf(thisId) === -1) {
                return false;
            };
        };
        return true;
    }

    const filterResults = () => {

        const _filteredResults: Array<TrackOverview> = props.trackOverviews.filter((result: TrackOverview) => {
            const titleSearchTrackFound = stringIsInOtherString(props.filterText, result.displayTitle);
            const keywordsMatch = keywordsDoMatch(result.keywordIds);
            return titleSearchTrackFound && keywordsMatch;
        });
        setFilteredResults(_filteredResults);
    }

    const sortResults = () => {

        const _sortedResults = sortTrackOverviews(filteredResults, sortBy, sortDirection as string);

        let _trackIdToTableRowIndexDict: {[trackId: number]: number} = { };
        for (let i = 0; i < _sortedResults.length; i++) {
            _trackIdToTableRowIndexDict[_sortedResults[i].trackId] = i;
        };
        setTrackIdToTableRowIndexDict(_trackIdToTableRowIndexDict);

        setSortedResults(_sortedResults);
        setUpdateTableCount(updateTableCount + 1);

    };

    const rowClicked = (event: any) => {
        const trackId = event.rowData.trackId;
        props.trackSelected(trackId);
        //setLastSelectedRowId(trackId);
    };

    const sort = (params: any) => {
        
        setSortBy(params.sortBy);
        setSortDirection(params.sortDirection);
    };

    const rowRenderer = (args: any) => {
        if (args.rowData.visible === false) {
            args.className += " not-visible";
        } else if (args.rowData.trackId === props.selectedTrackId) {
            args.className += " selected-row";
        } else if (args.rowData.isEasterEgg === true) {
            args.className += " easter-egg";
        };
        return defaultRowRenderer(args);
    }

    const rowGetter = (indexObject: {index: number}): any => {
        const index = indexObject.index;
        return sortedResults[index];
    };

    // const displayTable: boolean = props.trackOverviews.length > 0;
    // if (displayTable) {
        return (
            <div className="material-font-family" id="track-table-wrapper">
                <Table
                    ref={(ref) => tableRef = ref}
                    width={tableDisplayParams.width} height={tableDisplayParams.height} headerHeight={showHeader ? 50 : 0} rowHeight={40} 
                    rowCount={sortedResults.length} 
                    rowGetter={rowGetter}
                    onRowClick={rowClicked}
                    sort={sort} sortBy={sortBy} 
                    sortDirection={sortDirection}
                    rowRenderer={rowRenderer}
                    scrollToIndex={scrollToIndex}>
                    {tableDisplayParams.showArtist ? <Column label="Artist" dataKey="artistName" width={tableDisplayParams.artistWidth} /> : null}
                    <Column label="Title" dataKey="displayTitle" width={tableDisplayParams.titleWidth} />
                    {tableDisplayParams.showTime ? <Column label="Time" dataKey="displayDuration" width={tableDisplayParams.timeWidth} /> : null}
                    {tableDisplayParams.showBpm ? <Column label="BPM" dataKey="bpm" width={tableDisplayParams.bpmWidth} /> : null}
                </Table>
            </div>
        );
    // } else {
    //     return (<div className={classes.loadingWaitWrapper + " flex-row-center-center"}>
    //         <div className="flex-row-start-center material-font-family">
    //             <CircularProgress />
    //             <div className={classes.loadingText}>Tracks are loading, please wait...</div>
    //         </div>
    //     </div>);
    // }
    
}


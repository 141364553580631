import React, { useState, useEffect } from 'react';
import '../css/generic.css';

export default function AboutThisWebsiteComponent() {
    return (<div className="material-font-family">
        The purpose of this website is to allow the listener to browse the music of <strong>Cylob</strong> in a way which is not possible on the usual music download or streaming sites. Here, the focus is on individual tracks instead of releases.<br/> <br/>

        Tracks may be searched for by <strong>title</strong> and <strong>keyword</strong>. In addition, a list of tracks <strong>similar</strong> to the selected one can be requested. This is based partly on the keywords, partly on the tempo. But the most significant factor is <strong>emotional content</strong>. This means "similar" tracks should share a certain mood, even if they are stylistically a little different.<br/> <br/>
        Finally, a <strong>random</strong> track may be requested, allowing the listener to find something which they may not have encountered before.

        <br/> <br/>
        <strong>Contact:</strong> info (at) cylobindustries (dot) com
    </div>)
}

import { getApiBaseAddr, sendRequest } from '../util/util_api.js';

import { fetchKeywords } from './api_keyword';
import { TrackOverview, TrackDetail, TrackOnRelease, Release, ReleaseGroup } from '../dtos/dto';

import { sortTrackOverviews } from '../util/track.util';

/*
public int TrackId { get; set; }
public string ClassName { get { return "TrackOverview"; } }
public string ArtistName { get; set; }
public string TitleMain { get; set; }
public string TitleSecondary { get; set; }
public int DurationSecs { get; set; }
*/
function fetchTrackOverviews(token: any, onSuccessCallbackFunc: (results: Array<TrackOverview>) => any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('GET', 'track/overviews', {}, token)
        .then(result => result.json())
        .then(result => {
            const results = new Array<TrackOverview>();
            // let count = 0;
            result.objects.forEach((entry: any) => {
                const trackOverview = TrackOverview.createFromDto(entry);
                results.push(trackOverview);
            });
            //const sortedTrackOverviews = sortTrackOverviews(results, 'displayTitle', 'DESC');
            //console.log('sortedTrackOverviews!!!  ', sortedTrackOverviews);
            onSuccessCallbackFunc(results);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

/*
public string ClassName { get { return "TrackDetail"; } }
public int? ArtistId { get; set; }
public TrackOverview TrackOverview { get; set; }	// contains TrackId
public int? RemixOfTrackId { get; set; }
public int? RemixArtistId { get; set; }
public float? Bpm { get; set; }
public int? ArtistIdSecondary { get; set; }

public ReleaseOverview[] AppearsOnReleases { get; set; } = { };
*/
// a track id of -1 fetches a random track
function fetchTrackDetail(trackId: number, onSuccessCallbackFunc: (trackDetail: TrackDetail) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + "track/detail/" + trackId)
        .then(result => result.json())
        .then(result => {
            if (result.objects.length > 0) {
                const dto: any = result.objects[0];
                const trackDetail = TrackDetail.createFromDto(dto);
                onSuccessCallbackFunc(trackDetail);
            }
            else {
                onErrorCallbackFunc("the track could not be found.");
            }
        },
        error => {
            onErrorCallbackFunc(error);
        });
}

function getIdOfRandomTrack(onSuccessCallbackFunc: (trackId: number) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + "track/rndid/")
        .then(result => result.json())
        .then(result => {
            if (result.objects.length > 0) {
                const trackId = result.objects[0];
                onSuccessCallbackFunc(trackId);
            };
        },
        error => {
            onErrorCallbackFunc(error);
        });
}

function fetchSimilarTrackIds(trackId: number, onSuccessCallbackFunc: (trackIds: Array<number>) => any, onErrorCallbackFunc = (err: any) => {}) {
    const params = { trackId, noOfItems: 10 };
    sendRequest('POST', 'track/closest-to', params)
        .then(result => result.json())
        .then(result => {
            if (result.objects != null) {
                onSuccessCallbackFunc(result.objects);
            } else {
                onSuccessCallbackFunc([]);
            }
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function submitNewTrack(token: any, params: any, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'track', params, token)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function submitEditTrack(token: any, trackId: number, params: any, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    
    sendRequest('PUT', 'track/' + trackId, params, token)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function submitDeleteTrack(token: any, trackId: number, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('DELETE', 'track/' + trackId, {}, token)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function getTrackKeywordNamesFromResult(result: any) {
    const objects = result.objects;
    const nameArray = new Array<string>();
    objects.forEach((obj: any) => {
        nameArray.push(obj.name);
    });
    return nameArray;
}

function fetchTrackKeywords(trackId: number, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
        fetch(getApiBaseAddr() + "trackkeyword/allkw/" + trackId)
            .then(res => res.json())
            .then((result) => {
                const nameArray = getTrackKeywordNamesFromResult(result);
                onSuccessCallbackFunc(nameArray);
            },
            (error) => {
                onErrorCallbackFunc(error);
            });
}

/*
    @param trackId the ID of the track (number)
    @param addKeywords: array of strings of keywords to add
    @param deleteKeywords: array of strings of keywords to delete
*/
function changeKeywordsForTrack(token: any, trackId: number, keywords: Array<string>, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {

    const params = { trackId, keywordList: keywords };

    sendRequest('POST', 'trackkeyword/setk4t', params, token)
        .then(result => result.json())
        .then(result => {
            // refetch all keywords (which are cached..)
            fetchKeywords((result) => {
                
            });
            //const nameArray = getTrackKeywordNamesFromResult(result);
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
        
}

function setMoodForTrack(token: any, trackId: number, moodX: number, moodY: number, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    const params = {
        trackId,
        mood: {
            polarity: moodX, 
            energy: moodY
        }
    };
    sendRequest('POST', 'mood/fortrack/', params, token)
        .then (result => result.json())
        .then(result => {
            
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        })
}

function getMoodForTrack(trackId: number, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'mood/fortrack/' + trackId)
        .then(result => result.json())
        .then(result => {
            if (result.objects != null) {
                if (result.objects.length > 0) {
                    const x = result.objects[0].polarity;
                    const y = result.objects[0].energy;
                    onSuccessCallbackFunc({x, y});
                } else {
                    onSuccessCallbackFunc({x: 0, y: 0});
                }
                
            } else {
                onErrorCallbackFunc({error: 'response received but objects is null!'});
            }
        },
        error => {
            onErrorCallbackFunc(error);
        });
}
// visible: true / false
function changeVisibilityForAllTracks(token: any, visible: boolean, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'track/v4all', {visible}, token)
    .then (result => result.json())
    .then(result => {
        onSuccessCallbackFunc(result);
    })
    .catch(err => {
        onErrorCallbackFunc(err);
    });
}

function submitTrackOnRelease(token: any, trackOnRelease: TrackOnRelease, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'trackonrelease', trackOnRelease, token)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function getReleasesForTrackId(trackId: number, onSuccessCallbackFunc: (releases: Array<Release>) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + "release/fortrack/" + trackId)
    .then(result => result.json())
    .then(result => {
        
        const releases = new Array<Release>();
        if(result.objects != null) {
            result.objects.forEach((dto: any) => {
                const release = Release.createFromDto(dto);
                releases.push(release);
            });
        };
        onSuccessCallbackFunc(releases);
    },
    error => {
        onErrorCallbackFunc(error);
    });
}

export { fetchTrackOverviews, fetchTrackDetail, submitNewTrack, submitEditTrack, submitDeleteTrack, changeKeywordsForTrack, fetchTrackKeywords, setMoodForTrack, getMoodForTrack, changeVisibilityForAllTracks, fetchSimilarTrackIds, getIdOfRandomTrack, submitTrackOnRelease, getReleasesForTrackId }

import { getApiBaseAddr, sendRequest } from '../util/util_api.js';
import { NetHost, TrackOnNetHost } from '../dtos/dto';

// store the array after the first fetch since it is unlikely to be updated afterwards
let netHostArray = new Array<NetHost>();

// if they have already been fetched, just return the array:
// net hosts are unlikely to change after the initial pickup
// if one is added, just refresh the app!
function fetchNetHosts(onSuccessCallbackFunc: (netHosts: Array<NetHost>) => any, onErrorCallbackFunc: (err: any) => any = () => {}) {

    fetch(getApiBaseAddr() + 'nethost')
        .then(res => res.json())
        .then((result: any) => {
            netHostArray = new Array<NetHost>();
            result.forEach((dto: any) => {
                const netHost = NetHost.createFromDto(dto);
                netHostArray.push(netHost);
            });
            onSuccessCallbackFunc(netHostArray);
        },
        (err) => {
            console.log('there was an error fetching net hosts, it is ', err);
            onErrorCallbackFunc(err);
        });
}

function addEmbedCode(token: any, params: any, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'embedded-track', params, token)
        .then(result => result.json())
        .then(result => {

            onSuccessCallbackFunc(result);
        })
        .catch((err: any) => {
            onErrorCallbackFunc(err);
        });
}

function deleteEmbed(token: any, params: any, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'embedded-track/delete', params, token)
        .then(result => result.json())
        .then(result => {
            
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function fetchEmbedInfosForTrack(trackId: number, onSuccessCallbackFunc: (result: Array<TrackOnNetHost>) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'embedded-track/l4t/' + trackId)
        .then(result=> result.json())
        .then((result) => {
            const dtos = result.objects;
            const trackOnNetHostArray = new Array<TrackOnNetHost>();
            dtos.forEach((dto: any) => {
                const trackOnNetHost = TrackOnNetHost.createFromDto(dto);
                trackOnNetHostArray.push(trackOnNetHost);
            });
            
            onSuccessCallbackFunc(trackOnNetHostArray);
        },
        (error) => {
            console.log('there was an error fetching embed infos, it is ', error);
            onErrorCallbackFunc(error);
        });
}

function fetchEmbedInfosForTrackAndHost(trackId: number, netHostId: number, onSuccessCallbackFunc: (result: Array<TrackOnNetHost>) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'embedded-track/links?netHostId=' + netHostId + "&trackId=" + trackId)
    .then(result=> result.json())
    .then(result => {
        const dtos = result.objects;
        const trackOnNetHostArray = new Array<TrackOnNetHost>();
        dtos.forEach((dto: any) => {
            const trackOnNetHost = TrackOnNetHost.createFromDto(dto);
            trackOnNetHostArray.push(trackOnNetHost);
        });
        onSuccessCallbackFunc(trackOnNetHostArray);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

function fetchAvailableEmbedHostIdsForTrack(trackId: number, onSuccessCallbackFunc = (result: Array<number>) => {}, onErrorCallbackFunc = (err: any) => {}) {
    
    fetch(getApiBaseAddr() + 'embedded-track/anh4t/' + trackId)
        .then(result => result.json())
        .then(result => {
            
            onSuccessCallbackFunc(result.objects);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });

}

export { fetchNetHosts, addEmbedCode, deleteEmbed, fetchEmbedInfosForTrack, fetchEmbedInfosForTrackAndHost, fetchAvailableEmbedHostIdsForTrack }
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { fade, makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './css/flex.css';
import './css/generic.css';
import useWindowDimensions from './util/windowDimensions';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: '#aeaeae',
        boxShadow: '0px 0px',
        borderBottom: '2px solid #999',
        // marginRight: '20px'
    },
    logo: {
        marginLeft: '10px'
    },
    navTabWrapper: {
        // marginLeft: '80px',
        // backgroundColor: 'orange'
    },
    selectedTab: {
        backgroundColor: '#777'
    },
    copyrightNotice: {
        marginRight: '20px',
        fontSize: '0.8em'
    }
})

interface SiteHeaderProps {

}
const YellowTabs = withStyles({
    indicator: {
      backgroundColor: "yellow"
    }
})(Tabs);

export default function SiteHeaderComponent(props: SiteHeaderProps) {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const history = useHistory();
    const location = useLocation();
    const routeArray: Array<string> = ['/tracks', '/about'];
    const [navTabIndex, setNavTabIndex] = useState<number>(0);

    useEffect(() => {
        
        switch (location.pathname) {
            case '/tracks':
                setNavTabIndex(0);
                break;
            case '/about':
                setNavTabIndex(1);
                break;
            default:
                setNavTabIndex(0);
                break;
        };
    }, []);

    function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        setNavTabIndex(newValue);
        history.push(routeArray[newValue]);
    }

    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
// "0 0 82 11"
    const showCopyright: boolean = width >= 768;
    const showLogo: boolean = width >= 480;
    let logoWidth: string = width >= 900 ? "400px" : "250px";
    if (!showLogo) logoWidth = "120px";
    const logoHeight: string = width >= 900 ? "50px" : "35px";
    const viewBox = showLogo ? "0 0 82 11" : "0 0 26 11";
    const logo = (<div>
        <svg className={classes.logo} x="0px" y="0px" width={logoWidth} height={logoHeight} viewBox={viewBox} preserveAspectRatio="none">
            <defs>
                <filter id="f1" x="0" y="0">
                <feGaussianBlur in="SourceGraphic" stdDeviation="0.4" />
                </filter>
            </defs>
            <path d="M 5 1.5 L 3 1.5 L 2 2.5 L 2 6.5 L 3 7.5 L 5 7.5 M 7 3.5 L 7 6.5 L 8 7.5 L 9 7.5 L 10 6.5 M 7 9.5 L 9 9.5 L 10 8.5 L 10 3.5 M 12 1.5 L 12 6.5 L 13 7.5 L 14 7.5 L 15 6.5 M 17 4.5 L 17 6.5 L 18 7.5 L 19 7.5 L 20 6.5 L 20 4.5 L 19 3.5 L 18 3.5 L 17 4.5 M 22 1.5 L 22 7.5 L 24 7.5 L 25 6.5 L 25 4.5 L 24 3.5 L 22 3.5 M 27 7.5 L 30 7.5 M 32 1.5 L 35 1.5 M 33.5 1.5 L 33.5 7.5 M 32 7.5 L 35 7.5 M 37 7.5 L 37 4.5 L 38 3.5 L 39 3.5 L 40 4.5 L 40 7.5 M 45 1.5 L 45 7.5 L 43 7.5 L 42 6.5 L 42 4.5 L 43 3.5 L 45 3.5 M 47 3.5 L 47 6.5 L 48 7.5 L 49 7.5 L 50 6.5 L 50 3.5 M 52 6.5 L 53 7.5 L 54 7.5 L 55 6.5 L 55 5.5 L 52 5.5 L 52 4.5 L 53 3.5 L 54 3.5 L 55 4.5 M 57 1.5 L 57 6.5 L 58 7.5 L 59 7.5 L 60 6.5 M 57 3.5 L 60 3.5 M 62 7.5 L 62 4.5 L 63 3.5 L 64 3.5 L 65 4.5 M 67 3.5 L 68.5 3.5 L 68.5 7.5 M 67 7.5 L 70 7.5 M 67.5 1.5 L 68.5 1.5 L 68.5 2.5 L 67.5 2.5 L 67.5 1.5 M 72 5.5 L 75 5.5 L 75 4.5 L 74 3.5 L 73 3.5 L 72 4.5 L 72 6.5 L 73 7.5 L 75 7.5 M 77 6.5 L 78 7.5 L 79 7.5 L 80 6.5 L 80 5.5 L 77 5.5 L 77 4.5 L 78 3.5 L 79 3.5 L 80 4.5 " stroke="#888" strokeWidth="1.8" fill="none" filter="url(#f1)"/>
            <path d="M 5 1.5 L 3 1.5 L 2 2.5 L 2 6.5 L 3 7.5 L 5 7.5 M 7 3.5 L 7 6.5 L 8 7.5 L 9 7.5 L 10 6.5 M 7 9.5 L 9 9.5 L 10 8.5 L 10 3.5 M 12 1.5 L 12 6.5 L 13 7.5 L 14 7.5 L 15 6.5 M 17 4.5 L 17 6.5 L 18 7.5 L 19 7.5 L 20 6.5 L 20 4.5 L 19 3.5 L 18 3.5 L 17 4.5 M 22 1.5 L 22 7.5 L 24 7.5 L 25 6.5 L 25 4.5 L 24 3.5 L 22 3.5 M 27 7.5 L 30 7.5 M 32 1.5 L 35 1.5 M 33.5 1.5 L 33.5 7.5 M 32 7.5 L 35 7.5 M 37 7.5 L 37 4.5 L 38 3.5 L 39 3.5 L 40 4.5 L 40 7.5 M 45 1.5 L 45 7.5 L 43 7.5 L 42 6.5 L 42 4.5 L 43 3.5 L 45 3.5 M 47 3.5 L 47 6.5 L 48 7.5 L 49 7.5 L 50 6.5 L 50 3.5 M 52 6.5 L 53 7.5 L 54 7.5 L 55 6.5 L 55 5.5 L 52 5.5 L 52 4.5 L 53 3.5 L 54 3.5 L 55 4.5 M 57 1.5 L 57 6.5 L 58 7.5 L 59 7.5 L 60 6.5 M 57 3.5 L 60 3.5 M 62 7.5 L 62 4.5 L 63 3.5 L 64 3.5 L 65 4.5 M 67 3.5 L 68.5 3.5 L 68.5 7.5 M 67 7.5 L 70 7.5 M 67.5 1.5 L 68.5 1.5 L 68.5 2.5 L 67.5 2.5 L 67.5 1.5 M 72 5.5 L 75 5.5 L 75 4.5 L 74 3.5 L 73 3.5 L 72 4.5 L 72 6.5 L 73 7.5 L 75 7.5 M 77 6.5 L 78 7.5 L 79 7.5 L 80 6.5 L 80 5.5 L 77 5.5 L 77 4.5 L 78 3.5 L 79 3.5 L 80 4.5 " stroke="yellow" strokeWidth="0.4" fill="none"/>
        </svg>
    </div>);
    
    // logo 4 pic: width="1500px" height="250px" 
    return (<AppBar className={classes.appBar} position="sticky">
        <div className="flex-row-space-between-center">
            {/* <div className="flex-row-start-center"> */}
                {/* {showLogo ? logo : null} */}
                {showLogo ? logo : logo}
                <div className={classes.navTabWrapper} style={showCopyright ? {} : {marginRight: "20px"}}>
                    <YellowTabs value={navTabIndex} onChange={handleTabChange} aria-label="navigation tabs">
                        <Tab label="Tracks" {...a11yProps(0)} className={navTabIndex === 0 ? classes.selectedTab : ''}/>
                        <Tab label="About" {...a11yProps(1)} className={navTabIndex === 1 ? classes.selectedTab : ''}/>
                    </YellowTabs>
                </div>
            {/* </div> */}

            {showCopyright ? 
                <div className={classes.copyrightNotice + " material-font-family"}>&copy; 2020 Cylob Industries</div>
                : null}
        </div>
    </AppBar>)
}
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';

import ReleaseNameDisplayComponent from './ReleaseNameDisplay';
import { TrackOverview, TrackDetail, TrackOnNetHost, Release } from '../dtos/dto';
import ChipGroup from '../generic/ChipGroup';
import { outerWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

import '../css/generic.css';
import '../css/flex.css';


const useStyles = makeStyles({
    paper: {
        padding: '10px 15px',
        // backgroundColor: '#f9f9f9',
        margin: '10px'
    },
    chip: {
        marginRight: '5px'
    },
    icon: {
        color: '#aaa',
        marginRight: '10px'
    },
    propertyText: {
        marginRight: '20px',
        fontWeight: 'bold'
    },
    section: {
        width: '100%'
    },
    // divider: {
    //     margin: '10px 10px',
    //     height: '1px',
    //     width: 'calc(100% - 20px)',
    //     // backgroundColor: '#ccc'
    // },
    cellKey: {
        minWidth: '80px'
    },
    cellKeyText: {
        minWidth: '80px'
    },
    cellValue: {
        //width: '500px'
    },
    tableContainer: {
        // maxWidth: '500px'
    }, 
    releaseImg: {
        width: '150px',
        marginLeft: '10px'
    },
    chipWrapper: {
        //padding: '10px',
        width: 'calc(100% - 20px)',
    },
    chipWrapperClosed: {
        maxHeight: '37px',
        overflow: 'hidden'
    },
    chipWrapperExpanded: {

    },
    displayTitle: {
        fontSize: '1.4em'
    },
    trackDetailsWrapper: {
        maxWidth: '850px',
        // marginTop: '50px'
    },
    inbetweenArtistAndTitle: {
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    getSimilarButtonWrapper: {
        //marginLeft: '10px'
        // maxHeight: '40px'
    },
    getSimilarButton: {
        maxHeight: '36px',
        minWidth: '110px',
        marginLeft: '10px'
    },
    viewReleaseButton: {
        maxHeight: '36px',
        minWidth: '54px',
        marginLeft: '10px'
    },
    imgWrapper: {
        padding: '5px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    releaseInfos: {
        marginBottom: '5px'
    }

});

enum LayoutType {
    None = -1,
    Wide = 0,
    Narrow = 1
}

interface TrackInfoProps {
    trackDetail: TrackDetail;
    releaseImgFilePath: string;
    releasesForTrack: Array<Release>;
    keywordsForTrack: Array<string>;
    getSimilarButtonClicked: () => any;
    getReleaseButtonClicked: (releaseId: number) => any;
    keywordWasRequested: (keyword: string) => any;
}

interface TableRowInfo {
    key: string;
    displayKey: string;
    val: any;
}
export default function TrackInfoComponent(props: TrackInfoProps) {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const [chipGroupExpanded, setChipGroupExpanded] = useState<boolean>(false);
    const [keywordsForTrack, setKeywordsForTrack] = useState<Array<string>>([]);
    const [layoutType, setLayoutType] = useState<LayoutType>(LayoutType.Wide);
    const [elWidth, setElWidth] = useState<number>(800);

    useEffect(() => {
        setKeywordsForTrack(props.keywordsForTrack.sort());
    }, [props.keywordsForTrack]);

    useEffect(() => {
        calcLayout();
    }, [width]);

    function handleGetSimilarClicked() {
        props.getSimilarButtonClicked();
    }
    function handleGetReleaseClicked(releaseId: number) {
        props.getReleaseButtonClicked(releaseId);
    }
    function handleChipNameClicked(chipName: string) {
        
        props.keywordWasRequested(chipName);
    }

    const releaseInfos = (<div>
        {props.releasesForTrack.map((release: Release, index: number) => {
            return (<div className={classes.releaseInfos + " flex-row-space-between-center"} key={index}>
                <ReleaseNameDisplayComponent release={release} includeVersionDescription={true}/>
                <Button className={classes.viewReleaseButton} 
                    variant="outlined" 
                    color="secondary" 
                    size="small" 
                    onClick={() => {handleGetReleaseClicked(release.id)}}>
                        <strong>View</strong>
                </Button>
            </div>);
        })}
    </div>);

    function handleChipGroupButtonClicked() {
        setChipGroupExpanded(!chipGroupExpanded);
    }
    
    const tableRowInfoArray: Array<TableRowInfo> = [
        {key: 'displayDuration', displayKey: 'Duration', val: props.trackDetail.trackOverview.displayDuration},
        {key: 'bpm', displayKey: 'BPM', val: props.trackDetail.bpm},
        // {key: 'releases', displayKey: 'Appears on', val: (<div>{releaseInfos}</div>)}
    ];
    if (props.releasesForTrack.length > 0) {
        tableRowInfoArray.push({key: 'releases', displayKey: 'Appears on', val: (<div>{releaseInfos}</div>)});
    };

    function calcLayout() {
        const el: HTMLElement | null = document.getElementById('track-info-wrapper');
        if (el == null) return;
        const _elWidth = outerWidth(el);
        setLayoutType(_elWidth >= 600 ? LayoutType.Wide : LayoutType.Narrow);
        setElWidth(_elWidth);
    }

    let topHalfClassName = '';
    let wrapperStyle = { };
    switch (layoutType) {
        case LayoutType.Narrow:
            topHalfClassName = 'flex-column-reverse-start-center';
            wrapperStyle = { marginTop: '10px' };
            break;
        case LayoutType.Wide:
            topHalfClassName = 'flex-row-start-center';
            wrapperStyle = { marginTop: '60px' };
            break;
    }

return (
    <div id="track-info-wrapper" className={classes.trackDetailsWrapper} style={wrapperStyle}>

        <div className={topHalfClassName}>
            <div>
                <TableContainer className={classes.tableContainer}>
                    <Table aria-label="track details" size="small">
                        <TableBody>
                            <TableRow key="artistName">
                                <TableCell className={classes.cellKey} colSpan={2}>
                                <div className={elWidth > 480 ? "flex-row-space-between-center" : "flex-column-start-center"}>
                                    <div className={classes.displayTitle}>
                                        <strong>{props.trackDetail.trackOverview.displayTitle}</strong>
                                        <span className={classes.inbetweenArtistAndTitle}> by </span>
                                        <strong>{props.trackDetail.trackOverview.artistName}</strong>
                                    </div>
                                    <div className={classes.getSimilarButtonWrapper}>
                                        <Button className={classes.getSimilarButton} variant="outlined" color="secondary" size="small" onClick={handleGetSimilarClicked}><strong>Get Similar</strong></Button>
                                    </div>
                                </div>
                                </TableCell>
                            </TableRow>
                            {tableRowInfoArray.map((info: TableRowInfo) => {
                                return (<TableRow key={info.key}>
                                    <TableCell className={classes.cellKey}>
                                        <strong className={classes.cellKeyText}>{info.displayKey}</strong>
                                    </TableCell>
                                    <TableCell className={classes.cellValue}>{info.val}</TableCell>
                                </TableRow>)
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className={classes.imgWrapper + " flex-column-start-start"}>
                <img className={classes.releaseImg + " release-image"} src={props.releaseImgFilePath}></img>
            </div>
        </div> {/* end of table + image row */}
        <TableContainer className={classes.tableContainer}>
            <Table aria-label="track details" size="small">
                <TableBody>
                    <TableRow key="keywords">
                        <TableCell className={classes.cellKey + " flex-row-start-center"}>
                            <strong>{keywordsForTrack.length} Keywords</strong>
                            <IconButton color="secondary" size="small" onClick={handleChipGroupButtonClicked}>
                                {chipGroupExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell className={classes.cellValue}>
                            <div className={classes.chipWrapper + " " + (chipGroupExpanded ? classes.chipWrapperExpanded : classes.chipWrapperClosed)}>
                                <ChipGroup 
                                    chipNames={keywordsForTrack}
                                    canDelete={false}
                                    canClick={true} 
                                    chipNameWasClicked={handleChipNameClicked}/>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </div>
);

}



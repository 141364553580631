import React, { useState, useEffect } from 'react';
import '../css/generic.css';

export default function AboutCylobComponent() {
    return (<div className="material-font-family">
        <strong>Cylob</strong> is an electronic music producer from the UK. Active since 1993, he has released 17 singles and 15 albums since then, on the labels <strong>Rephlex, Breakin' Records, Power Vacuum</strong> and his own <strong>Cylob Industries</strong> imprint. <br/><br/>
        Although Cylob's music is predominantly instrumental, ranging from ambient styles to aggressive dance-floor sonics via pop pastiche and electronic funk, his most well known tracks have been the underground hits <strong>"Rewind"</strong> and <strong>"Cut The Midrange, Drop The Bass"</strong>, which pioneered computer-generated rapping and singing on top of signature Cylob beats. <br/><br/>
        His remix credits include reworkings for <strong>Mogwai, Aphex Twin, Soulwax (2 many DJs), Cristian Vogel</strong> and <strong>Mike Flowers Pops.</strong>
    </div>)
}

import React, { useState, useEffect } from 'react';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

interface AppleMusicEmbedProps {
    srcString: string;
}

export default function AppleMusicEmbed(props: AppleMusicEmbedProps) {

    const { height, width } = useWindowDimensions();
    const [appleWrapperWidth, setAppleWrapperWidth] = useState<number>(300);

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width])

    // get a unique frame title without using the whole src string if possible
    const splitSrc = props.srcString.split('/album/');
    const frameTitle = splitSrc[1] || splitSrc[0];


    function calcLayout() {

        respondToParentWidth('apple-music-embed-wrapper', (width: number) => {
            setAppleWrapperWidth(width * 0.9);
        });
    }


    // todo: use usestyle
    const itunesStyle = {
        width: '100%',
        maxWidth: '660px',
        overflow: 'hidden',
        background: 'transparent'
    };

    const appleMusicWrapperStyle = {
        width: appleWrapperWidth
    }


    return (<div id="apple-music-embed-wrapper" style={appleMusicWrapperStyle}>
        <iframe title={frameTitle} allow="autoplay *; encrypted-media *;" frameBorder="0" height="140" style={itunesStyle} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" src={props.srcString}></iframe>
    </div>)
}
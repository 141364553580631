import { 
    getApiBaseAddr, 
    // sendRequest 
} from '../util/util_api.js';

import { Keyword } from '../dtos/dto';

let keywords = new Array<Keyword>();

function fetchKeywords(onSuccessCallbackFunc: (keywords: Array<Keyword>) => any, onErrorCallbackFunc = (err: any) => {}) {
    if (keywords.length > 0) {
        onSuccessCallbackFunc(keywords);
        return;
    };
    fetch (getApiBaseAddr() + 'keyword')
        .then(result => result.json())
        .then(result => {
            keywords = new Array<Keyword>();
            const keywordObjects = result.objects;
            keywordObjects.forEach((keywordObject: any) => {
                const keyword = new Keyword();
                keyword.id = keywordObject.id;
                keyword.name = keywordObject.name;
                keywords.push(keyword);
            })
            onSuccessCallbackFunc(keywords);
        },
        error => {
            onErrorCallbackFunc(error);
        });

}

function getKeywordNameForId(id: number): string | null {
    const indexOfKeyword = keywords.findIndex(keyword => keyword.id === id);
    if (indexOfKeyword !== -1) {
        return keywords[indexOfKeyword].name;
    };
    return null;
}

function getKeywordIdForName(name: string): number {
    const compareName = name.toLowerCase();
    const indexOfKeyword = keywords.findIndex(keyword => keyword.name.toLowerCase() === compareName);
    if (indexOfKeyword !== -1) {
        return keywords[indexOfKeyword].id;
    };
    return -1;
}

export { fetchKeywords, getKeywordNameForId, getKeywordIdForName }
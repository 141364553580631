import { TrackOverview } from '../dtos/dto';
const randomWords = require('random-words');

// https://stackoverflow.com/questions/2332811/capitalize-words-in-string
const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export function getReleaseImageSrcForCatNo(catNo: string): string {
    // remove spaces
    const fileNameBasis = catNo.toLowerCase().replace(/\s+/g, '');
    const filePath: string = process.env.PUBLIC_URL + 'images/releases/' + fileNameBasis + '.jpg';
    return filePath;
}

export function getRandomTrackOverview(trackId: number): TrackOverview {
    const trackOverview = new TrackOverview();
    trackOverview.trackId = trackId;
    trackOverview.artistName = "Cylob";
    const rndWords = randomWords(5);
    let titleMain = '';
    let noOfWords = 2;
    if (Math.random() > 0.5) {
        noOfWords = 3;
    }
    for (let i = 0; i < noOfWords; i++) {
        titleMain += rndWords[i] + ' ';
    };
    trackOverview.titleMain = capitalize(titleMain.trim());
    if (Math.random() > 0.5) {
        trackOverview.titleSecondary = capitalize(rndWords[3] + ' ' + rndWords[4]);
    }
    trackOverview.bpm = 70 + Math.floor(Math.random() * 80);
    trackOverview.durationSecs = 120 + Math.floor(Math.random() * 120);
    trackOverview.visible = true;
    return trackOverview;
}

export function sortTrackOverviews(trackOverviewArray: Array<TrackOverview>, sortBy: string, sortDirection: string): Array<TrackOverview> {
    const sortedResults = trackOverviewArray.sort((a: TrackOverview, b: TrackOverview) => {
        let thisProperty: string | number = '', otherProperty: string | number = '';
        switch (sortBy) {
            case "displayTitle":
                thisProperty = a.displayTitle.toLowerCase();
                otherProperty = b.displayTitle.toLowerCase();
                break;
            case "artistName":
                thisProperty = a.artistName.toLowerCase();
                otherProperty = b.artistName.toLowerCase();
                break;
            case "displayDuration":
                thisProperty = a.displayDuration;
                otherProperty = b.displayDuration;
                break;
            case "bpm":
                thisProperty = a.bpm;
                otherProperty = b.bpm;
                break;
        };
        let result = 0;
        if (thisProperty > otherProperty) {
            result = -1;
        } else if (thisProperty < otherProperty) {
            result = 1;
        };

        //let result = a.getSortResult(b, sortBy);
        result *= sortDirection === "ASC" ? 1 : -1;
        return result;
    });
    return sortedResults;
}
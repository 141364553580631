import React, { useState, useEffect } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import { NetHost } from '../dtos/dto';
import { fetchNetHosts } from '../api/api_nethost';

import '../css/flex.css';
import '../css/generic.css';

import "font-awesome/css/font-awesome.css";

const useStyles = makeStyles({
    button: {
        // marginLeft: '5px',
        // marginRight: '5px'

    },
    buttonGroup: {
        // margin: '10px 15px'
        marginBottom: '10px',
        width: '100%',
        // backgroundColor: 'yellow'
    },
    buttonContainerDiv: {
        width: '20%',
        // backgroundColor: 'orange',
    },
    buttonDivDisabled: {
        color: '#eee',
        // backgroundColor: 'red'
    },
    buttonDiv: {
        margin: '3px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: 'calc(90% - 20px)',
        paddingTop: '5px',
        paddingBottom: '5px',
        // color: 'black',
        border: '1px solid #bbb',
        borderRadius: '3px',
        
        '&:active': {
            backgroundColor: '#eaeaea'
        }
    },
    buttonDivAvailable: {
        color: '#777'
    },
    buttonDivActive: {
        color: 'red',
        backgroundColor: '#eaeaea'
    }
});

interface EmbedButtonsProps {
    availableNetHostIds: Array<number>;
    selectedNetHostId: number;
    netHostWasSelectedWithId: (netHostId: number) => any;
}

function EmbedButtons(props: EmbedButtonsProps) {
    const netHostAvailableDefault: {[key: string]: boolean } = { };
    const [netHostAvailable, setNetHostAvailable] = useState(netHostAvailableDefault);
    const nameChoices = ['youtube', 'soundcloud', 'bandcamp', 'spotify', 'apple'];
    const toolTipLabels = ['YouTube Embedded Player', 'SoundCloud Embedded Player', 'Bandcamp Embedded Player', 'Spotify Embedded Player', 'Apple Music Embedded Player'];
    const [availableNetHostNames, setAvailableNetHostNames] = useState(new Array<string>());
    const [selectedName, setSelectedName] = useState('');
    const [netHostArray, setNetHostArray] = useState(new Array<NetHost>());

    
    useEffect(() => {
        
        fetchNetHosts((_netHostArray: Array<NetHost>) => {
            
            setNetHostArray(_netHostArray);
        });
    }, []);

    useEffect(() => {
        if ((netHostArray.length > 0) && (props.availableNetHostIds.length > 0)) {
            calcAvailableNetHostNames();
        };
    }, [netHostArray, props.availableNetHostIds] );

    useEffect(() => {
        ;
        const available: {[key: string]: boolean } = { };
        nameChoices.forEach(name => {
            available[name] = availableNetHostNames.includes(name);
        });
        setNetHostAvailable(available);
        
    }, [availableNetHostNames]);

    useEffect(() => {
        if (netHostArray.length > 0) {
            const netHost: NetHost | undefined = netHostArray.find((n: NetHost) => n.id === props.selectedNetHostId);
            if (netHost != null) {
                setSelectedName(netHost.name.toLowerCase());
            } else {
                setSelectedName('');
            }
        };
    }, [netHostArray, props.selectedNetHostId])

    function calcAvailableNetHostNames() {
        if (props.availableNetHostIds == null) return;
        const _availableNetHostNames = new Array<string>();
        props.availableNetHostIds.forEach((netHostId: number) => {
            const netHost: NetHost | undefined = netHostArray.find((n: NetHost) => n.id === netHostId);
            if (netHost != null) {
                _availableNetHostNames.push(netHost?.name.toLowerCase());
            };
        });
        setAvailableNetHostNames(_availableNetHostNames);
    }

    function buttonClicked(event: any) {
        const buttonName = event.currentTarget.getAttribute('data-name');
        const selectedNetHost: NetHost | undefined = netHostArray.find((netHost: NetHost) => {
            return netHost.name.toLowerCase() === buttonName;
        });
        if (selectedNetHost != null) {
            const id = selectedNetHost?.id;
            if (id !== null) {
                props.netHostWasSelectedWithId(id ?? 0);
            };
        };
    }
    const classes = useStyles();

    return (
        <div className={classes.buttonGroup + " flex-row-space-between-center"}>

            {nameChoices.map((name: string, index: number) => {
                let disabled = !(netHostAvailable[name] === true);
                let className = classes.buttonDiv + ' flex-row-center-center';
                if (disabled) {
                    className += (' ' + classes.buttonDivDisabled);
                } else {
                    className += (' ' + classes.buttonDivAvailable);
                    if (selectedName === name) className += (' ' + classes.buttonDivActive);
                }
                const buttonDiv = (<div data-name={name} className={className} 
                onClick={(e) => !disabled ? buttonClicked(e) : null} >
                    <Icon className={"fa fa-" + name} fontSize="default" />
                </div>);
                
                if (!disabled) {
                    return (<div className={classes.buttonContainerDiv} key={name}>
                        <Tooltip title={toolTipLabels[index]} aria-label={toolTipLabels[index]}>
                            {buttonDiv}
                        </Tooltip>
                    </div>);
                } else {
                    return (<div className={classes.buttonContainerDiv} key={name}>
                        {buttonDiv}
                    </div>);
                }
            })}
        </div>
    );
}

export default EmbedButtons;

// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Auth0Provider } from "./auth0/react-auth0-spa";
import config from "./auth_config.json";
import history from "./util/history";
import './css/generic.css';
// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

serviceWorker.unregister();


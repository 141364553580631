import React, { useState, useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import TrackInfoComponent from './TrackInfo';

import EmbedButtons from '../embed/EmbedButtons';

import { getTrackEmbedParams, getRenderForEmbeddedInfo } from '../util/util_embed.js';
import { fetchEmbedInfosForTrackAndHost, fetchAvailableEmbedHostIdsForTrack } from '../api/api_nethost';

import { TrackOverview, TrackDetail, TrackOnNetHost, Release } from '../dtos/dto';
import { fetchTrackDetail, getReleasesForTrackId } from '../api/api_track';
import { getKeywordNameForId } from '../api/api_keyword';
import { getReleaseImageSrcForCatNo } from '../util/track.util';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

import '../css/generic.css';
import '../css/modal.css';

// after demo at https://material-ui.com/components/snackbars/
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles({
    chipWrapper: {
        padding: '10px',
        width: 'calc(100% - 20px)'
    },
    chip: {
        marginRight: '5px',
        marginBottom: '5px'
    },
    getSimilarButtonWrapper: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    noTrackSelectedWrapper: {
        marginTop: '80px',
        textAlign: 'left',
        // backgroundColor: 'yellow',
        width: '400px',
        height: '300px'
    },
    allWrapper: {
        // backgroundColor: 'blue'
    },
    embed: {
        marginTop: '20px'
    },
    trackDetailWrapper: {
        marginRight: '20px'
        // height: '100%',
        // overflowY: 'scroll'
    },
    returnButtonWrapper: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    cellKey: {
        width: '60px'
    },
});

interface TrackDetailComponentProps {
    trackId: number;
    selectedNetHostId: number;
    netHostWasSelectedWithId: (netHostId: number) => any;
    similarWasRequested: () => any;
    releaseWasRequested: (release: Release) => any;
    keywordWasRequested: (keyword: string) => any;
    showModalCloseButton: boolean;
    onClose: () => any;
}
export default function TrackDetailComponent(props: TrackDetailComponentProps) {

    const classes = useStyles();
    const { height, width } = useWindowDimensions();

    const [modalType, setModalType] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // allow for multiple embeds for a web host
    const [embeddedInfoRenderArray, setEmbeddedInfoRenderArray] = useState(new Array<any>());
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [keywordsForTrack, setKeywordsForTrack] = useState(new Array<string>());
    const [availableNetHostIds, setAvailableNetHostIds] = useState(new Array<number>());
    const [trackDetail, setTrackDetail] = useState(new TrackDetail());
    const [releasesForTrack, setReleasesForTrack] = useState(new Array<Release>());
    const [releaseImgFilePath, setReleaseImgFilePath] = useState('');

    const [showListenText, setShowListenText] = useState<boolean>(false);

    useEffect(() => {
        fetchTrack();
        fetchAvailableEmbedHostIds();
    }, [props.trackId]);

    useEffect(() => {
        fetchEmbedInfos();
    }, [trackDetail, props.selectedNetHostId]);

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width])

    function calcLayout() {
        respondToParentWidth('track-detail-wrapper', (width: number) => {
            setShowListenText(width > 450);
        });
    }

    function fetchTrack() {
        if (props.trackId == null) {
            return;
        };
        const callbackFunc = (_trackDetail: TrackDetail) => {
            setTrackDetail(_trackDetail);
            if (_trackDetail.trackOverview.keywordIds != null) {
                const _keywordsForTrack = new Array<string>();
                _trackDetail.trackOverview.keywordIds.forEach((id: number) => {
                    _keywordsForTrack.push(getKeywordNameForId(id) ?? '');
                });
                setKeywordsForTrack(_keywordsForTrack);
            };
        }
        fetchTrackDetail(props.trackId, callbackFunc);
        getReleasesForTrackId(props.trackId, (result: Array<Release>) => {
            
            setReleasesForTrack(result);
            if (result.length > 0) {
                // // just get the image for the first release..
                const release: Release = result[0];
                const catNo: string = release.catNo;
                const filePath: string = getReleaseImageSrcForCatNo(catNo);
                setReleaseImgFilePath(filePath);
            } else {
                setReleaseImgFilePath('');
            };
        });
    }

    function fetchEmbedInfos() {
        
        if (props.trackId != null) {
            const netHostId = props.selectedNetHostId;
            fetchEmbedInfosForTrackAndHost(props.trackId, netHostId, (result: Array<TrackOnNetHost>) => {
                
                const _embeddedInfoRenderArray = new Array<any>();
                result.forEach((object: any) => {
                    // embeddedInfoJson is a string encoded json object
                    const render: any = getRenderForEmbeddedInfo(object.embeddedInfoJson);
                    if (render != null) {
                        _embeddedInfoRenderArray.push(render);
                    };
                });
                setEmbeddedInfoRenderArray(_embeddedInfoRenderArray);
            })
        } else {
            setEmbeddedInfoRenderArray([]);
        };
    }

    function fetchAvailableEmbedHostIds() {
        fetchAvailableEmbedHostIdsForTrack(props.trackId, (result) => {
            
            setAvailableNetHostIds(result);
        });
    }

    function netHostWasSelectedWithId(id: number) {
        props.netHostWasSelectedWithId(id);
    }

    function getSimilarClicked() {
        props.similarWasRequested();
    }

    function getReleaseClicked(releaseId: number) {
        
        const release = releasesForTrack.find((r: Release) => r.id === releaseId);
        if (release != null) {
            props.releaseWasRequested(release);
        }
    }


    // ADMIN: modal dialog to edit details
    function openModal(type: any) {
        if (!modalIsOpen) {
            setModalIsOpen(true);
            setModalType(type);
        };
    }

    function editTrackClicked() {
        openModal('editTrack');
    }

    function editTrackKeywordsClicked() {
        openModal('editTrackKeywords');
    }

    function editTrackOnReleaseClicked() {
        openModal('editTrackOnRelease');
    }

    function addEmbedClicked() {
        openModal('editTrackEmbed');
    }

    function editMoodClicked() {
        openModal('editMood');
    }

    function deleteTrackClicked() {
        openModal('confirm');
    }
    function deleteEmbedClicked() {
        openModal('confirm_delete_embed');
    }

    function handleTrackDetailsDidChange() {
        // console.log('handleTrackDetailsDidChange not implemented');
    }

    function setSnackbarParams(sp: any) {
        setSnackbarIsOpen(sp.isOpen);
        setSnackbarSeverity(sp.severity);
        setSnackbarMessage(sp.message);
    }

    function handleSnackbarClose() {
        setSnackbarIsOpen(false);
    }

    function handleModalClose() {
        setModalIsOpen(false);
    }

    function handleKeywordWasRequested(chipName: string) {
        props.keywordWasRequested(chipName);
        props.onClose();
    }


    let displayed: any = (<div className={classes.noTrackSelectedWrapper + " material-font-family well-padded-text"}>No track has been selected</div>);
    if (props.trackId != null && props.trackId > -1) {
        displayed = (<div className={classes.trackDetailWrapper}>
            {props.showModalCloseButton ? 
                (<div className={classes.returnButtonWrapper}><Button variant="outlined" size="small" onClick={props.onClose}>Return To Browser</Button></div>) 
            : null}
            <TrackInfoComponent 
                trackDetail={trackDetail}
                releaseImgFilePath={releaseImgFilePath}
                releasesForTrack={releasesForTrack}
                keywordsForTrack={keywordsForTrack}
                getSimilarButtonClicked={getSimilarClicked}
                getReleaseButtonClicked={getReleaseClicked} 
                keywordWasRequested={handleKeywordWasRequested}/>
            <div style={{height: "10px"}}></div>
            <div>
                <TableContainer>
                    <Table aria-label="embed buttons" size="small">
                        <TableBody>
                            <TableRow key="embedButtons">
                                {showListenText ? (<TableCell className={classes.cellKey}>
                                    <strong>
                                        <div>Listen</div>
                                    </strong>
                                </TableCell>) : null}
                                <TableCell colSpan={showListenText ? 1 : 2}>
                                    <EmbedButtons 
                                        selectedNetHostId={props.selectedNetHostId} 
                                        netHostWasSelectedWithId={netHostWasSelectedWithId}
                                        availableNetHostIds={availableNetHostIds} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.embed + " flex-row-center-center"}>
                    {embeddedInfoRenderArray.map(embed => embed)}
                </div>
            </div>

            <div style={{height: "10px"}}></div>
            
            <Snackbar open={snackbarIsOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>);
    };

    return (<div id="track-detail-wrapper" className={classes.allWrapper}>{displayed}</div>)


}


import React from 'react';
import './css/App.css';
import './css/generic.css';
import './css/index.css';
// import { useAuth0 } from "./auth0/react-auth0-spa";
//import MainPageComponent from './pages/MainPage';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import TracksPageComponent from './track/TracksPageComponent';
import AboutPageComponent from './pages/AboutPage';
import SiteHeaderComponent from './SiteHeader';
// import useWindowDimensions from './util/windowDimensions';

function App() {
    // const { height, width } = useWindowDimensions();
    return (<div style={{height: "100%"}}>

        <Router>
            <SiteHeaderComponent  />
            <Switch>
                <Route path="/tracks">
                    <TracksPageComponent />
                </Route>
                <Route path="/about">
                    <AboutPageComponent />
                </Route>
                <Route path="/">
                    <TracksPageComponent />
                </Route>
            </Switch>
        </Router>

        {/* <div>width: <span>{width}</span> ~ height: <span>{height}</span></div> */}
    </div>);

}

export default App;

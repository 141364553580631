import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import "font-awesome/css/font-awesome.css";

import ExclusiveButtonGroup from '../generic/ExclusiveButtonGroup';
import { UserOption } from './TracksPageComponent';

import '../css/TrackHeader.css';
import '../css/flex.css';
import { outerWidth } from '../util/css.util';

import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/More';
import HistoryIcon from '@material-ui/icons/History';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Tooltip from '@material-ui/core/Tooltip';
import useWindowDimensions from '../util/windowDimensions';

const useStyles = makeStyles({
    header: {
        width: '100%',
        // marginLeft: '20px'
    },
    innerWrapper: {
        // marginRight: '20px',
        // backgroundColor: 'green'
    },
    randomButton: {
        // marginRight: '20px'
    },
    // title: {
    //     fontSize: '1.8em',
    //     marginLeft: '50px'
    // },
    buttonsWrapper: {
        width: '470px',
        margin: '0px',
        // marginTop: '10px'
    },
    tracksText: {
        // marginRight: '20px',
        fontWeight: 'bold',
        fontSize: '1.0em',
        maxWidth: '100px'
    }

});

interface TrackHeaderProps {
    selectedUserOption: UserOption;
    totalNoOfTracks: number;
    totalDurationInSecs: number;
    onRandomTrackRequested: () => any;
    onUserOptionSelected: (userOption: UserOption) => any;
}


export default function TrackHeader(props: TrackHeaderProps) {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const [selectedTableIndex, setSelectedTableIndex] = useState(0);
    const [totalDurationString, setTotalDurationString] = useState<string>('');
    const originalTableOptionNames = ["all", "search", "related", "history"];
    const detailOptionNames = ["detail", "keywords"];

    useEffect(() => {
        const index: number = originalTableOptionNames.findIndex((optionName: string) => optionName === props.selectedUserOption);
        if (index != -1) {
            setSelectedTableIndex(index);
        };
    }, [props.selectedUserOption]);

    useEffect(() => {
        setTotalDurationString(new Date(props.totalDurationInSecs * 1000).toISOString().substr(11, 8));
    }, [props.totalDurationInSecs]);

    function userOptionWasSelected(index: number) {
        props.onUserOptionSelected(originalTableOptionNames[index] as UserOption);
    }


    function detailOptionWasSelected(index: number) {

    }

    function handleRandomClicked() {
        props.onRandomTrackRequested();
    }

    // const el: any = document.getElementById("track-header-wrapper");
    // const thisWidth = el != null ? outerWidth(el) : 9999;
    const thisWidth = width >= 768 ? width * 0.5 : width;
    const showTotals: boolean = thisWidth > 600 ? true : false;
    const buttonSize = thisWidth >= 480 ? "medium" : "small";
    const buttonsFlex = "flex-row-space-between-center";
    // thisWidth >= 480 ? "flex-row-space-between-center" : "flex-column-start-center";
    const buttonStyle = thisWidth >= 480 ? {paddingTop: '0px'} : {paddingTop: '10px'};
    const randomLegend = thisWidth >= 400 ? "Random" : "Rnd";
    const tableOptionNames = ["all", "search", "related", "history"];
    const tableOptionIcons = [
        <ListIcon />, <SearchIcon />, <MoreIcon />, <HistoryIcon />
    ];
    /*
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/More';
import HistoryIcon from '@material-ui/icons/History';
    */
    // thisWidth >= 400 ?  : ["all", "srch", "rel", "hist"];
    return (
        <div id="track-header-wrapper" className={classes.header}>
            <div className={classes.innerWrapper + " flex-row-space-evenly-center"}>
                {showTotals ? (<div className="flex-column-start-start material-font-family">
                    <div className={classes.tracksText}>{props.totalNoOfTracks} Tracks</div>
                    <div className={classes.tracksText}>{totalDurationString}</div>
                </div>) : null}
                {/* <div className={classes.buttonsWrapper}> */}
                    <div className="button-group" style={buttonStyle}>
                        <ExclusiveButtonGroup 
                            optionNames={tableOptionNames}
                            optionIcons={tableOptionIcons}
                            useIcons={thisWidth < 400}
                            selectedIndex={selectedTableIndex}
                            optionWasSelected={userOptionWasSelected}
                            buttonSize={buttonSize} />
                    </div>
                    <div style={buttonStyle}>
                    <Tooltip title="Get Random Track" aria-label="Get Random Track">
                        <Button className={classes.randomButton} variant="outlined" color="secondary" size="small" onClick={handleRandomClicked}>
                            {thisWidth < 400 ? <ShuffleIcon /> : randomLegend}
                            </Button>
                        </Tooltip>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
    
}

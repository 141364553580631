import { Base } from './base.dto';

export class TrackOnNetHost extends Base {
    netHostId: number = -1;
    trackId: number = -1;
    embeddedInfoJson: string = '{}';
    static createFromDto(dto: any): TrackOnNetHost {
        const instance = new TrackOnNetHost();
        Object.assign(instance, super.createFromDto(dto));
        instance.netHostId = dto.netHostId;
        instance.trackId = dto.trackId;
        instance.embeddedInfoJson = dto.embeddedInfoJson;
        return instance;
    }
}
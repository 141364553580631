import { Base } from './base.dto';

export class ReleaseGroupOnNetHost extends Base {
    releaseGroupId: number = -1;
    netHostId: number = -1;
    linkAddr: string = '';

    static createFromDto(dto: any): ReleaseGroupOnNetHost {
        const instance = new ReleaseGroupOnNetHost();
        Object.assign(instance, super.createFromDto(dto));
        instance.releaseGroupId = dto.releaseGroupId;
        instance.netHostId = dto.netHostId;
        instance.linkAddr = dto.linkAddr;
        return instance;
    }

    public toDto(): Object {
        const dto: any = super.toDto();
        dto.releaseGroupId = this.releaseGroupId;
        dto.netHostId = this.netHostId;
        dto.linkAddr = this.linkAddr;
        return dto;
    }

}
import React, { useState, useEffect } from 'react';
import '../css/flex.css';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

interface YouTubeEmbedProps {
    videoId: string;
}

export default function YouTubeEmbed(props: YouTubeEmbedProps) {

    const { height, width } = useWindowDimensions();
    const [iframeWidth, setIframeWidth] = useState<number>(300);

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width]);

    function calcLayout() {
        respondToParentWidth('youtube-embed-wrapper', (width: number) => {
            setIframeWidth(width * 0.9);
        });
    }

    const iframeTitle: string = 'youtube-embed_' + props.videoId;
    const srcString: string = 'https://www.youtube.com/embed/' + props.videoId + '?autoplay=0';

    return (<div id="youtube-embed-wrapper">
            <iframe title={iframeTitle} id="ytplayer" 
            // type="text/html" 
            width={iframeWidth} height="140" src={srcString} frameBorder="0"></iframe>

        </div>);
}


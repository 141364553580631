import React, { useState, useEffect } from 'react';
import { respondToParentWidth } from '../util/css.util';
import useWindowDimensions from '../util/windowDimensions';

interface SoundCloudEmbedProps {
    trackId: string;
}

export default function SoundCloudEmbed(props: SoundCloudEmbedProps) {

    const { height, width } = useWindowDimensions();
    const [iframeWidth, setIframeWidth] = useState<number>(300);
    const [iframeHeight, setIframeHeight] = useState<number>(140);
    const [visual, setVisual] = useState<string>('false');

    useEffect(() => {
        setTimeout(() => {
            calcLayout();
        }, 400)
        
    }, [])

    useEffect(() => {
        calcLayout();
    }, [width]);

    function calcLayout() {
        respondToParentWidth('soundcloud-embed-wrapper', (width: number) => {
            setIframeWidth(width - 20);
            setIframeHeight(width > 400 ? 140 : 300);
            setVisual(width > 400 ? 'false' : 'true')
        });
    }

    const iframeTitle: string = 'soundcloud-embed_' + props.trackId;
    const srcString: string = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/' + props.trackId + '&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=' + visual;

    return (<div id="soundcloud-embed-wrapper">
            <iframe title={iframeTitle} 
                width={iframeWidth} 
                height={iframeHeight} 
                scrolling="no" 
                frameBorder="no" 
                allow="autoplay" 
                src={srcString}>
            </iframe>
    </div>);
}

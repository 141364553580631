import React, { useState } from 'react';
import { TrackOverview, TrackDetail, TrackOnNetHost, Release } from '../dtos/dto';

interface ReleaseNameDisplayProps {
    release: Release;
    includeVersionDescription?: boolean;
}

export default function ReleaseNameDisplayComponent(props: ReleaseNameDisplayProps) {
    return (<div>
        <strong>{props.release.title}</strong>
        {props.includeVersionDescription ? (<span>{" (" + props.release.versionDescription + ")"}</span>) : (<span></span>)}
    </div>)
}
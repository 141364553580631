import React, { useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AboutCylobComponent from './AboutCylob';
import AboutCylobIndustriesComponent from './AboutCylobIndustries';
import AboutThisWebsiteComponent from './AboutThisWebsite';
import AboutHistoryComponent from './AboutHistory';
import AboutEasterEggsComponent from './AboutEasterEggs';
import '../css/generic.css';
import '../css/flex.css';
import '../css/modal.css';
import useWindowDimensions from '../util/windowDimensions';
import { getModalStyles } from '../util/css.util';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    wrapper: {
        height: '100%',
        width: '100%',
        marginTop: '20px',
        // backgroundColor: 'blue'
        // minHeight: '700px'
    },
    listWrapper: {
        width: '200px',
        // backgroundColor: 'orange',
        height: '500px',
        marginLeft: '20px'
        // borderRight: '1px solid #eee'
    },
    list: {
        width: '180px'
    },
    selectedBackground: {
        backgroundColor: '#eee'
    },
    infoTextWrapper: {
        marginLeft: '30px',
        marginTop: '5px',
        width: '400px',
        textAlign: 'left',
        // backgroundColor: 'yellow'
    },
    returnButton: {
        marginBottom: '20px'
    }
});

export default function AboutPageComponent() {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();

    const [visibleSectionIndex, setVisibleSectionIndex] = useState(0);
    const [showAboutInModal, setShowAboutInModal] = useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setShowAboutInModal(width < 768);
        setModalIsOpen(false);
    }, [width]);

    function handleModalClose() {
        setModalIsOpen(false);
    }


    function handleButtonClicked(index: number) {
        setVisibleSectionIndex(index);
        if (showAboutInModal) {
            setModalIsOpen(true);
        };
    }

    let visibleSection = (<div></div>);
    switch (visibleSectionIndex) {
        case 0:
            visibleSection = (<AboutCylobComponent />);
            break;
        case 1:
            visibleSection = (<AboutCylobIndustriesComponent />);
            break;
        case 2:
            visibleSection = (<AboutThisWebsiteComponent />);
            break;
        case 3:
            visibleSection = (<AboutEasterEggsComponent />);
            break;
        case 4:
            visibleSection = (<AboutHistoryComponent />);
            break;
    }

    const listItemArray: Array<string> = ['Cylob', 'Cylob Industries', 'This Website', 'Easter Eggs', 'History'];

    const modalStyles = getModalStyles(width, height);

    return (<div className={classes.wrapper}>
        
        <div className={showAboutInModal ? "flex-row-center-center" : "flex-row-start-start"}>
            <div>
                <div className={classes.listWrapper + " flex-column-start-start"}>
                    <List className={classes.list} component="nav" aria-label="about navigation">
                        {listItemArray.map((listItem: string, index: number) => {
                            return (<div key={index}>
                                <ListItem button className={visibleSectionIndex === index ? classes.selectedBackground : ''} onClick={() => handleButtonClicked(index)}>
                                    <ListItemText primary={listItem} />
                                </ListItem>
                                <Divider />
                            </div>)
                        
                        })}
                    </List>
                </div>
            </div>
            {showAboutInModal ? null : (<div className={classes.infoTextWrapper}>{visibleSection}</div>)}
        </div>
        <Modal open={modalIsOpen} onClose={handleModalClose}>
            <div style={modalStyles} className="modal-wrapper">
                <Button className={classes.returnButton} variant="outlined" color="primary" onClick={handleModalClose}>Return</Button>
                {visibleSection}
            </div>
        </Modal>
    </div>)
}
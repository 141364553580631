import { Base } from './base.dto';

// TODO: releaseDate not yet implemented
export class Release extends Base {
    title: string = '';
    versionDescription: string = '';
    releaseGroupId: number = -1;
    catNo: string = '';
    // in backend is DateTime instance
    releaseDate: any = undefined;
    labelId: number = -1;

    public get displayDescription(): string {
        return this.title + ' (' + this.versionDescription + ')';
    }

    static createFromDto(object: any): Release {
        const instance = new Release();
        Object.assign(instance, super.createFromDto(object));
        instance.title = object.title;
        instance.versionDescription = object.versionDescription;
        instance.releaseGroupId = object.releaseGroupId;
        instance.catNo = object.catNo;
        instance.releaseDate = object.releaseDate;
        instance.labelId = object.labelId;
        return instance;
    }

    public toDto(): Object {
        const dto: any = super.toDto();
        dto.title = this.title;
        dto.versionDescription = this.versionDescription;
        dto.releaseGroupId = this.releaseGroupId;
        dto.catNo = this.catNo;
        dto.releaseDate = this.releaseDate;
        dto.labelId = this.labelId;
        return dto;
    }
}
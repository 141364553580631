function getApiBaseAddr() {
    //return "https://localhost:44320/api/";
    // return "http://dorning44-001-site1.htempurl.com/api/";
   // return "https://cylobindustries.com/api/";
   return process.env.REACT_APP_API_ADDR;
}

/*
    method: 'POST', 'PUT', 'DELETE'
    endpoint: 'track', 'artist', etc....
*/
async function sendRequest(method, endpoint, params = {}, token) {
    const url = getApiBaseAddr() + endpoint;
    const headers = {
        'Content-Type': 'application/json'
    };
    if (token != null) {
        headers['Authorization'] = `Bearer ${token}`
    };
    const fetchParams = {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };
    if (method !== 'GET') {
        const body = JSON.stringify(params);
        fetchParams.body = body;
    };
    const response = await fetch(url, fetchParams);
    //console.log("RESPONSE IS...", response)
    return response;
}

export { getApiBaseAddr, sendRequest }
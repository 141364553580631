import { Base } from './base.dto';
import { getDisplayDuration, getDisplayTitle } from '../util/utilities';

export class TrackOverview extends Base {
    id: number = -1;
    trackId: number = -1;
    count: number = -1;
    artistName: string = '';
    bpm: number = 0;
    isEasterEgg: boolean = false;
    public _titleMain: string = '';
    public get titleMain(): string {
        return this._titleMain;
    }
    public set titleMain(newVal: string) {
        this._titleMain = newVal;
        this._updateDisplayTitle();
    }
    private _titleSecondary: string = '';
    public get titleSecondary(): string {
        return this._titleSecondary;
    }
    public set titleSecondary(newVal: string) {
        this._titleSecondary = newVal;
        this._updateDisplayTitle();
    }
    // combined main and secondary in brackets
    private _displayTitle: string = '';
    public get displayTitle(): string {
        
        return this._displayTitle;
    }
    keywordIds = new Array<number>();
    visible: boolean = false;
    private _durationSecs: number = 0;
    public get durationSecs(): number {
        return this._durationSecs;
    }
    public set durationSecs(newVal: number) {
        this._durationSecs = newVal;
        this._updateDisplayDuration();
    }
    private _displayDuration: string = '00:00';
    public get displayDuration(): string {
        return this._displayDuration;
    }

    private _updateDisplayTitle() {
        this._displayTitle = getDisplayTitle(this._titleMain, this._titleSecondary);
        
    }
    private _updateDisplayDuration() {
        this._displayDuration = getDisplayDuration(this.durationSecs);
    }

    static createFromDto(dto: any): TrackOverview {
        const instance = new TrackOverview();
        Object.assign(instance, super.createFromDto(dto));
        instance.trackId = dto.trackId;
        instance.artistName = dto.artistName;
        instance.titleMain = dto.titleMain;
        instance.titleSecondary = dto.titleSecondary;
        instance.durationSecs = dto.durationSecs;
        instance.keywordIds = dto.keywordIds;
        instance.visible = dto.visible;
        instance.bpm = dto.bpm;
        
        return instance;
    }

    public getSortResult(other: TrackOverview, propertyName: string): number {
        let thisProperty: string = '', otherProperty: string = '';
        switch (propertyName) {
            case "title":
                thisProperty = this.displayTitle;
                otherProperty = other.displayTitle;
                break;
            case "artistName":
                thisProperty = this.artistName;
                otherProperty = other.artistName;
                break;
            case "duration":
                thisProperty = this.displayDuration;
                otherProperty = other.displayDuration;
                break;
        };
        let result = 0;
        if (thisProperty > otherProperty) {
            result = -1;
        } else if (thisProperty < otherProperty) {
            result = 1;
        };
        
        return result;
    }

}
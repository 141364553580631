import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import TrackFilterParams from './TrackFilterParams';
import TrackTable from './TrackTable';
import '../css/TrackBrowser.css';
import '../css/generic.css';
import '../css/flex.css';

import { TrackOverview } from '../dtos/dto';
import { UserOption } from './TracksPageComponent';
import { getRandomTrackOverview } from '../util/track.util';
import { FilterParams } from './TracksPageComponent';
import useWindowDimensions from '../util/windowDimensions';
import { outerHeight, outerWidth } from '../util/css.util';

const useStyles = makeStyles({
    explainText: {
        margin: '5px 20px',
    }
});

interface TrackBrowserProps {
    trackOverviews: Array<TrackOverview>;
    trackUpdateCount: number;
    selectedTrackId: number;
    selectedUserOption: UserOption;
    // first number in array is id of the track itself, the others are the ids of the tracks similar to that one
    similarTrackIds: Array<number>;
    similarDescription: any;
    trackSelected: (trackId: number) => any;

    // filterParams: FilterParams;
    // filterParamsChanged: (filterParams: FilterParams) => any;

    // mechanism to add keyword to search params
    // TODO: something more elegant..
    // addKeywordCount: number;
    // keywordToAdd: string;

    filterText: string;
    keywords: Array<string>;

    filterTextChanged: (filterText: string) => any;
    keywordsChanged: (keywords: Array<string>) => any;
}

export default function TrackBrowserComponent(props: TrackBrowserProps) {

    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const [trackOverviewHistory, setTrackOverviewHistory] = useState(new Array<TrackOverview>());
    const [similarTracks, setSimilarTracks] = useState(new Array<TrackOverview>());
    const [trackTableUiChangeCount, setTrackTableUiChangeCount] = useState(0);
    const [tableWidth, setTableWidth] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [searchIsCompact, setSearchIsCompact] = useState<boolean>(false);

    useEffect(() => {
        calcTableDimensions();
    }, [height, width, trackTableUiChangeCount]);

    useEffect(() => {

        let historyIdArray: Array<number> = getHistoryIdArray();
        setTrackOverviewHistory(convertHistoryTrackIdsToTrackOverviewArray(historyIdArray));
        
    }, [props.trackOverviews]);


    useEffect(() => {
        const _similarTracks = new Array<TrackOverview>();
        props.similarTrackIds.forEach((trackId: number) => {
            const findFunc = (trackOverview: TrackOverview) => {
                return trackOverview.trackId === trackId;
            };
            let trackOverview: TrackOverview | undefined = props.trackOverviews.find(findFunc);
            if (trackOverview != null) {
                _similarTracks.push(trackOverview);
            } else {
                // it's an easter egg
                trackOverview = getRandomTrackOverview(trackId);
                trackOverview.isEasterEgg = true;
                _similarTracks.push(trackOverview);
            };
            setSimilarTracks(_similarTracks);
        });
    }, [props.similarTrackIds]);

    useEffect(() => {
        addTrackToHistory(props.selectedTrackId);
    }, [props.selectedTrackId])

    useEffect(() => {
        setTrackTableUiChangeCount(trackTableUiChangeCount + 1);
    }, [props.selectedUserOption]);

    function trackSelected(trackId: number) {
        props.trackSelected(trackId);
    }

    function getHistoryIdArray(): Array<number> {
        // get existing list of ids from localstorage
        let historyIds: any = window.localStorage.getItem('trackBrowserHistoryIds');
        let historyIdArray: Array<number> | null = JSON.parse(historyIds);
        if (historyIdArray == null) {
            historyIdArray = new Array<number>();
        };
        return historyIdArray;
    }

    function addTrackToHistory(trackId: number) {
        if (trackId < 0) return;
        // if we are browsing history, don't add things on to the end of it
        if (props.selectedUserOption === 'history') return;

        let historyIdArray: Array<number> = getHistoryIdArray();
        if (historyIdArray.indexOf(trackId) === -1) {
            historyIdArray.push(trackId);
        };
        window.localStorage.setItem('trackBrowserHistoryIds', JSON.stringify(historyIdArray));
        setTrackOverviewHistory(convertHistoryTrackIdsToTrackOverviewArray(historyIdArray));
    }

    function convertHistoryTrackIdsToTrackOverviewArray(trackIds: Array<number>): Array<TrackOverview> {
        const trackOverviewArray = new Array<TrackOverview>();
        trackIds.forEach((trackId: number) => {
            const findFunc = (trackOverview: TrackOverview) => {
                return trackOverview.trackId === trackId;
            };
            let trackOverview: TrackOverview | undefined = props.trackOverviews.find(findFunc);
            if (trackOverview == null) {
                // it's an easter egg
                trackOverview = getRandomTrackOverview(trackId);
                trackOverview.isEasterEgg = true;
            };
            trackOverviewArray.push(trackOverview);
        });
        return trackOverviewArray;
    }

    function handleClearHistory() {
        setTrackOverviewHistory(new Array<TrackOverview>());
        window.localStorage.setItem('trackBrowserHistoryIds', JSON.stringify([]));
    }

    function handleFilterTextChanged(filterText: string) {
        props.filterTextChanged(filterText);
    }

    function handleKeywordsChanged(keywords: Array<string>) {
        props.keywordsChanged(keywords);
    }

    function calcTableDimensions() {
        let _tableHeight = height - 95;
        let _tableWidth = width * (width >= 768 ? 0.48 : 1);
        _tableWidth -= (width >= 768 ? 0 : 40);
        // _tableWidth += (width >= 768 ? 20 : 0);
        const trackBrowserElement = document.getElementById('track-browser');
        if (trackBrowserElement != null) {
            const boundingRect = trackBrowserElement.getBoundingClientRect();
            const top: number = boundingRect.top;
            _tableHeight = height - top;
            _tableHeight = _tableHeight - 30;
        };
        const trackBrowserOptionalUI = document.getElementById('track-browser-optional-ui');
        if (trackBrowserOptionalUI != null) {
            //const boundingRect = trackBrowserOptionalUI.getBoundingClientRect();
            _tableHeight -= outerHeight(trackBrowserOptionalUI);
            //console.log('height comparison: ', trackBrowserOptionalUI.getBoundingClientRect().height, outerHeight(trackBrowserOptionalUI))
            //boundingRect.height;
        };
        setTableWidth(_tableWidth);
        setTableHeight(_tableHeight);
        setSearchIsCompact(width < 480);
    }

    // filterParams is used by the filter params component but also by the table in order to filter things
    // if there's no filtering ('all') then make the filter params blank
    let thisFilterText = '';
    let thisKeywords = new Array<string>();
    // let thisFilterParams: FilterParams = {filterText: '', keywords: []};
    let optionalUi: any = (<div></div>);
    let trackOverviews: Array<TrackOverview> = props.trackOverviews;

    switch (props.selectedUserOption) {
        case "all":
            break;
        case "search":
            thisFilterText = props.filterText;
            thisKeywords = props.keywords;
            optionalUi = (
                <TrackFilterParams 
                    filterText={props.filterText} 
                    keywords={thisKeywords}
                    filterTextChanged={handleFilterTextChanged}
                    keywordsChanged={handleKeywordsChanged}
                    compact={searchIsCompact} />
            );
            break;
        case "related":
            trackOverviews = similarTracks;
            if (similarTracks.length > 0) {
                optionalUi = (<div className="material-font-family padded-text">
                    {props.similarDescription}
                </div>);
            } else {
                optionalUi = (<div className="material-font-family padded-text flex-column-start-start">
                    <div className={classes.explainText}>Select a track under <strong>All</strong> or <strong>Search</strong> and then select <strong>Get Similar</strong>  or <strong>View</strong> a release.</div>
                    <div className={classes.explainText}>The related tracks will be listed here.</div>
                </div>);
            };
            break;
        case "history":
            optionalUi = (<div>
                <Button onClick={handleClearHistory}>Clear History</Button>
            </div>);
            trackOverviews = trackOverviewHistory;
            break;
    }

    return (
        <div id="track-browser-wrapper">
            <div id="track-browser-optional-ui">{optionalUi}</div>
            <TrackTable 
                tableWidth={tableWidth}
                tableHeight={tableHeight}
                trackSelected={trackSelected} 
                trackOverviews={trackOverviews}
                trackUpdateCount={props.trackUpdateCount} 
                filterText={thisFilterText} 
                keywords={thisKeywords}
                selectedTrackId={props.selectedTrackId}
                uiChangeCount={trackTableUiChangeCount}/>
        </div>
    );

}

import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import TrackHeader from './TrackHeader';
import TrackBrowserComponent from './TrackBrowser';
import TrackDetailComponent from './TrackDetail';
import ReleaseGroupsOnNetHostComponent from './ReleaseGroupsOnNetHost';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../css/TracksPage.css';
import '../css/flex.css';
import '../css/modal.css';

import { fetchTrackDetail, fetchTrackOverviews, fetchTrackKeywords, fetchSimilarTrackIds, getIdOfRandomTrack } from '../api/api_track';
import { getTrackIdsForReleaseWithId, getReleaseGroupOnNetHostsForRelease } from '../api/api_release';
import { fetchNetHosts } from '../api/api_nethost';
import { NetHost, TrackOverview, Release, ReleaseGroupOnNetHost } from '../dtos/dto';
import ReleaseNameDisplayComponent from './ReleaseNameDisplay';
import { getReleaseImageSrcForCatNo } from '../util/track.util';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../util/windowDimensions';
import { getModalStyles } from '../util/css.util';

const useStyles = makeStyles({
    releaseImg: {
        width: '60px'
    },
    releaseImgSmall: {
        width: '40px'
    },
    tracksOnReleaseText: {
        margin: '5px 20px',
    },
    buyButton: {
        marginLeft: '20px'
    },
    loadingWaitWrapper: {
        width: '600px',
        height: '500px'
    },
    loadingText: {
        marginLeft: '50px'
    },
    trackDetailWrapper: {
        // height: '600px',
        overflowY: 'auto'
    }
});

export type UserOption = "all" | "search" | "related" | "history";

export interface FilterParams {
    filterText: string;
    keywords: Array<string>;
}

export default function TracksPageComponent() {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();

    const [trackOverviews, setTrackOverviews] = useState(new Array<TrackOverview>());
    const [trackUpdateCount, setTrackUpdateCount] = useState(0);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [netHosts, setNetHosts] = useState(new Array<NetHost>()); 
    const [selectedNetHostId, setSelectedNetHostId] = useState(-1);
    const [similarTrackIds, setSimilarTrackIds] = useState(new Array<number>());
    const [similarDescription, setSimilarDescription] = useState((<div></div>));
    const [trackIdBreadcrumbs, setTrackIdBreadcrumbs] = useState([]);
    const [token, setToken] = useState(null);
    const [selectedTrackId, setSelectedTrackId] = useState(-1);
    const defaultUserOption: UserOption = "all";
    const [selectedUserOption, setSelectedUserOption] = useState(defaultUserOption as UserOption);

    // for buy popover
    const [buyModalIsOpen, setBuyModalIsOpen] = useState<boolean>(false);

    const [filterText, setFilterText] = useState('');
    const [keywords, setKeywords] = useState(new Array<string>());
    const [currentlySelectedRelease, setCurrentlySelectedRelease] = useState<Release>(new Release());
    const [releaseGroupOnNetHostArray, setReleaseGroupOnNetHostArray] = useState<Array<ReleaseGroupOnNetHost>>([]);
    const [displayLoadingSpinner, setDisplayLoadingSpinner] = useState<boolean>(true);
    const [totalDurationInSecs, setTotalDurationInSecs] = useState<number>(0);
    const [detailAppearsInModal, setDetailAppearsInModal] = useState<boolean>(false);
    const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        performFetchTrackOverviews();
        fetchNetHosts((result: Array<NetHost>) => {
            result.forEach(netHost => {
                netHost.name = netHost.name.toLowerCase();
            });
            setNetHosts(result);
        });
    }, []);

    useEffect(() => {
        performFetchTrackOverviews();
    }, [token]);

    useEffect(() => {
        // console.log('track id breadcrumbs is now: ', trackIdBreadcrumbs);
    }, [trackIdBreadcrumbs]);

    useEffect(() => {
        // console.log('selected user option is now..', selectedUserOption)
    }, [selectedUserOption]);

    useEffect(() => {
        let _totalDurationInSecs = 0;
        trackOverviews.forEach((trackOverview: TrackOverview) => {
            _totalDurationInSecs += trackOverview.durationSecs;
        });
        setTotalDurationInSecs(_totalDurationInSecs);
    }, [trackOverviews]);

    useEffect(() => {
        setDetailAppearsInModal(width < 768);
        if (width >= 768) {
            setDetailModalIsOpen(false);
        };
    }, [width]);

    function performFetchTrackOverviews() {
        fetchTrackOverviews(token, (result: Array<TrackOverview>) => {
            setTrackOverviews(result);
            setTrackUpdateCount(trackUpdateCount + 1);
            setDisplayLoadingSpinner(false);
        }, error => {
            console.log('failed to fetch track overviews, error was ', error);
        });
    }

    // called when track is selected - fetch track detail
    function fetchTrack(trackId: number) {
        setSelectedTrackId(trackId);
        if (detailAppearsInModal) {
            setDetailModalIsOpen(true);
        };
    }

    function trackWasSelected(trackId: number) {
        fetchTrack(trackId);
    }

    function randomTrackWasRequested() {
        
        getIdOfRandomTrack((trackId) => {
            setSelectedTrackId(trackId);
            setSelectedUserOption('all');
            setTrackUpdateCount(trackUpdateCount + 1);
            if (detailAppearsInModal) {
                setTimeout(() => {
                    setDetailModalIsOpen(true);
                }, 100)
                
            };
        });
    }

    function handleSimilarRequested() {
        setDetailModalIsOpen(false);
        if (selectedTrackId != null) {
            setDisplayLoadingSpinner(true);
            fetchSimilarTrackIds(selectedTrackId, (result: Array<number>) => {
                // first number in array is id of the track itself, the others are the ids of the similar tracks
                result.splice(0, 0, selectedTrackId);
                setSimilarTrackIds(result);
                // console.log('similar track ids are ', result);
                setTimeout(() => {
                    setSelectedUserOption('related');
                    const trackOverview = trackOverviews.find((t: TrackOverview) => t.trackId === selectedTrackId);
                    setDisplayLoadingSpinner(false);
                    setSimilarDescription(
                        (<div>Tracks similar to <strong>{trackOverview?.displayTitle}</strong></div>)
                    );
                }, 100);
                
            });
        };
    }

    function handleBuyClicked(event: any)  {
        setBuyModalIsOpen(true)
      };

    function handleBuyOnClose() {
        setBuyModalIsOpen(false)
    }

    function handleDetailModalOnClose() {
        setDetailModalIsOpen(false);
        setTrackUpdateCount(trackUpdateCount + 1);

        //setSelectedTrackId(selectedTrackId);
        // setTimeout(() => {
        //     setTrackUpdateCount(trackUpdateCount + 1);
        // }, 400)
        
    }

    function getNetHostNameForId(netHostId: number): string {
        const netHost: NetHost | undefined = netHosts.find((netHost: NetHost) => netHost.id === netHostId);
        if (netHost != null) {
            return netHost.name;
        } else {
            return 'unknown'
        };
    }

    function handleReleaseRequested(release: Release) {
        setDetailModalIsOpen(false);
        setCurrentlySelectedRelease(release);
        setDisplayLoadingSpinner(true);
        const releaseGroupOnNetHostSortFunc = (a: ReleaseGroupOnNetHost, b: ReleaseGroupOnNetHost) => {
            if (getNetHostNameForId(a.netHostId) < getNetHostNameForId(b.netHostId)) {
                return -1;
            }
            if (getNetHostNameForId(a.netHostId) > getNetHostNameForId(b.netHostId)) {
                return 1;
            }
            return 0;
        }
        
        getReleaseGroupOnNetHostsForRelease(release.id, (result: Array<ReleaseGroupOnNetHost>) => {
            // setReleaseCanBeBought(result.length > 0 ? true : false);
            setReleaseGroupOnNetHostArray(result.sort(releaseGroupOnNetHostSortFunc));
            fetchTrackIdsForRelease(release, result.length > 0);
        });
        
    }

    function fetchTrackIdsForRelease(release: Release, releaseCanBeBought: boolean) {
        getTrackIdsForReleaseWithId(release.id, (result: Array<number>) => {
            setDisplayLoadingSpinner(false);
            setSimilarTrackIds(result);
            setSelectedUserOption('related');
            const imgSrc = getReleaseImageSrcForCatNo(release.catNo);

            const imgClassName = width > 480 ? classes.releaseImg : classes.releaseImgSmall;
            
            setSimilarDescription(<div className="flex-row-start-center">
                <div>
                    <img className={imgClassName + " release-image-small"} src={imgSrc}></img>
                </div>
                <div className="flex-column-start-start">
                    <div className={classes.tracksOnReleaseText}>Tracks on release</div>
                    <div className={classes.tracksOnReleaseText}>
                        <ReleaseNameDisplayComponent release={release} includeVersionDescription={true}/>
                    </div>
                </div>
                {releaseCanBeBought ? (<div>
                    <Button className={classes.buyButton} variant="outlined" color="secondary" size="small" onClick={handleBuyClicked}><strong>Buy</strong></Button>
                </div>) : null}
            </div>)
        });
    }

    function userAdminChanged(newVal: boolean) {
        setUserIsAdmin(newVal);
    }

    function netHostWasSelectedWithId(netHostId: number) {
        if (netHostId === selectedNetHostId) {
            setSelectedNetHostId(-1);
        } else {
            setSelectedNetHostId(netHostId);
        };
    }

    function handleUserOptionSelected(userOption: UserOption) {
        setSelectedUserOption(userOption);
    }

    function handleKeywordRequested(keyword: string) {
        // console.log('handleKeywordRequested', keyword, keywords);
        if (keywords.indexOf(keyword) === -1) {
            const _keywords = [...keywords];
            _keywords.push(keyword);
            // console.log('setting kwyrods to ', _keywords);
            setKeywords(_keywords);
        };
        setSelectedUserOption('search');
    }

    function handleFilterTextChanged(_filterText: string) {
        setFilterText(_filterText);
    }

    function handleKeywordsChanged(_keywords: Array<string>) {
        setKeywords(_keywords);
    }

    const modalStyles = getModalStyles(width, height);

    const displayDetail: boolean = width >= 768;
    const trackDetailStyle = {height: height - 80};

    const trackHeader = (<div id="track-header" className="flex-row-start-center">
        <TrackHeader 
            selectedUserOption={selectedUserOption}
            totalNoOfTracks={trackOverviews.length}
            totalDurationInSecs={totalDurationInSecs}
            onRandomTrackRequested={randomTrackWasRequested} 
            onUserOptionSelected={handleUserOptionSelected} />
    </div>);

    const trackDetailComponent = (<TrackDetailComponent 
        trackId={selectedTrackId} 
        selectedNetHostId={selectedNetHostId} 
        netHostWasSelectedWithId={netHostWasSelectedWithId}
        similarWasRequested={handleSimilarRequested} 
        releaseWasRequested={handleReleaseRequested}
        keywordWasRequested={handleKeywordRequested}
        showModalCloseButton={detailModalIsOpen && !displayDetail}
        onClose={handleDetailModalOnClose}/>);

    const trackBrowserComponent = (<div id="track-browser">
        {!displayLoadingSpinner ? 
        <TrackBrowserComponent 
            trackOverviews={trackOverviews}
            trackUpdateCount={trackUpdateCount}
            trackSelected={trackWasSelected}
            selectedTrackId={selectedTrackId} 
            selectedUserOption={selectedUserOption}
            similarTrackIds={similarTrackIds} 
            similarDescription={similarDescription}
            filterText={filterText}
            keywords={keywords}
            filterTextChanged={handleFilterTextChanged}
            keywordsChanged={handleKeywordsChanged} /> 
            :
            (<div className={classes.loadingWaitWrapper + " flex-row-center-center"}>
                <div className="flex-row-start-center material-font-family">
                    <CircularProgress />
                    
                </div>
            </div>)
        }
    </div>);

/*
(<Modal
open={detailModalIsOpen}
onClose={handleDetailModalOnClose}>
    <div style={modalStyles}>
        {trackDetailComponent}
    </div>
</Modal>)
*/

    const tracksPageWrapperStyle = detailModalIsOpen ? {display: 'none'} : {};
    return(<div>
        {detailModalIsOpen ? 
        (<div id="tracks-narrow-page-wrapper">
            <div id="track-details-narrow">
                <div id="track-details-narrow-inside" style={{height: height - 50}}>
                    {trackDetailComponent}
                </div>
            </div>
        </div>) : null}

        <div id="tracks-page-wrapper" style={tracksPageWrapperStyle}>
            {trackHeader}
            {trackBrowserComponent}
            {displayDetail ? 
                <div id="track-details" className={classes.trackDetailWrapper} style={trackDetailStyle}>
                    {trackDetailComponent}
                </div>  
            : null}
            
        </div>

        <Modal
            open={buyModalIsOpen}
            onClose={handleBuyOnClose}>
            <div style={modalStyles} className="modal-wrapper">
                <ReleaseGroupsOnNetHostComponent 
                    release={currentlySelectedRelease} 
                    netHostArray={netHosts}
                    releaseGroupOnNetHostArray={releaseGroupOnNetHostArray}
                    onClose={handleBuyOnClose}/>
            </div>
        </Modal>
        
    </div>);

}

import { getApiBaseAddr, sendRequest } from '../util/util_api.js';

import { Release, ReleaseGroupOnNetHost } from '../dtos/dto';

function fetchReleases(onSuccessCallbackFunc: (results: Array<Release>) => any, onErrorCallbackFunc = (err: any) => {}) {

    fetch(getApiBaseAddr() + 'release')
        .then(res => res.json())
        .then((result: any) => {
            const releaseArray = new Array<Release>();
            result.forEach((dto: any) => {
                const release = Release.createFromDto(dto);
                releaseArray.push(release);
            });
            onSuccessCallbackFunc(releaseArray);
        },
        (err) => {
            console.log('there was an error fetching releases, it is ', err);
            onErrorCallbackFunc(err);
        });
}

function fetchReleaseWithId(id: number, onSuccessCallbackFunc: (result: Release) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'release/' + id)
        .then(result => result.json())
        .then(result => {
            if (result.objects.length > 0) {
                const dto: any = result.objects[0];
                const release = Release.createFromDto(dto);
                onSuccessCallbackFunc(release);
            };
            
        },
        error => {
            onErrorCallbackFunc(error);
        });
}

function submitNewRelease(token: any, params: Release, onSuccessCallbackFunc: any, onErrorCallbackFunc = (err: any) => {}) {
    sendRequest('POST', 'release', params, token)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result);
        })
        .catch(err => {
            onErrorCallbackFunc(err);
        });
}

// "tracksonrelease/{releaseId}
function getTrackIdsForReleaseWithId(releaseId: number, onSuccessCallbackFunc: (result: Array<number>) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'release/tracksonrelease/' + releaseId)
        .then(result => result.json())
        .then(result => {
            onSuccessCallbackFunc(result.objects);
        },
        error => {
            onErrorCallbackFunc(error);
        });
}

function getReleaseGroupOnNetHostsForRelease(releaseId: number,onSuccessCallbackFunc: (result: Array<ReleaseGroupOnNetHost>) => any, onErrorCallbackFunc = (err: any) => {}) {
    fetch(getApiBaseAddr() + 'release/storesforgroup/' + releaseId)
        .then(result => result.json())
        .then(result => {
            const releaseGroupOnNetHostArray = new Array<ReleaseGroupOnNetHost>();
            result.objects.forEach((dto: any) => {
                const releaseGroupOnNetHost = ReleaseGroupOnNetHost.createFromDto(dto);
                releaseGroupOnNetHostArray.push(releaseGroupOnNetHost);
            });
            onSuccessCallbackFunc(releaseGroupOnNetHostArray);
        },
        error => {
            onErrorCallbackFunc(error);
        });
}

export { fetchReleases, submitNewRelease, fetchReleaseWithId, getTrackIdsForReleaseWithId, getReleaseGroupOnNetHostsForRelease }
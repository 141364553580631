/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export function outerHeight(element: HTMLElement) {
    const height: number = element.offsetHeight;
    const style: any = window.getComputedStyle(element);
    return ['top', 'bottom']
        .map(side => parseInt(style[`margin-${side}`]))
        .reduce((total, side) => total + side, height);
}

export function outerWidth(element: HTMLElement) {
    const width: number = element.offsetWidth;
    const style: any = window.getComputedStyle(element);
    return ['right', 'left']
        .map(side => parseInt(style[`margin-${side}`]))
        .reduce((total, side) => total + side, width);
}

export function respondToParentWidth(wrapperId: string, onCalcFunc: (width: number) => any) {
    const el: HTMLElement | null = document.getElementById(wrapperId);
    if (el == null) return;
    const parentElement: HTMLElement | null = el.parentElement;
    if (parentElement == null) return;
    const elWidth = outerWidth(parentElement);
    onCalcFunc(elWidth);
}

export function getModalStyles(width: number, height: number): any {
    let modalWidth = width - 40;
    if (modalWidth > 600) modalWidth = 600;
    let modalHeight = height - 100;
    if (modalHeight > 800) modalHeight = 800;
    const modalStyles: any = {
        position: 'absolute',
        width: modalWidth + 'px',
        height: modalHeight + 'px',
        padding: '15px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflowY: 'auto'
    };
    return modalStyles;
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import ChipGroup from '../generic/ChipGroup';
import { fetchKeywords } from '../api/api_keyword';
import { Keyword } from '../dtos/dto';
import { stringIsEmptyOrNull } from '../util/utilities';

import '../css/flex.css';

const useStyles = makeStyles({
    textAndKeywordSearchWrapper: {
        maxWidth: '600px'
    },
    searchTextField: {
        maxWidth: '250px'
    },
    searchForKeyword: {
        marginTop: '3px'
    }
})

interface TrackFilterProps {
    // filterParams: FilterParams;
    filterText: string;
    keywords: Array<string>;
    filterTextChanged: (filterText: string) => any;
    keywordsChanged: (keywords: Array<string>) => any;
    // filterParamsChanged: (filterParams: FilterParams) => any;
    compact: boolean;
}

export default function TrackFilterParamsComponent(props: TrackFilterProps) {

    const classes = useStyles();

    const [keywordStringsForTrack, setKeywordStringsForTrack] = useState<Array<string>>(props.keywords);
    const [allKeywordStrings, setAllKeywordStrings] = useState<Array<string>>([]);
    const [currentlyEnteredNewKeyword, setCurrentlyEnteredNewKeyword] = useState<string>('');

    useEffect(() => {
        fetchKeywords((keywords: Array<Keyword>) => {
            setAllKeywordStrings(keywords.map((keyword: Keyword) => keyword.name).sort());
        })
    }, []);

    useEffect(() => {
        props.keywordsChanged(keywordStringsForTrack);
    }, [keywordStringsForTrack]);

    useEffect(() => {
        setKeywordStringsForTrack(props.keywords);
    }, [props.keywords])

    function searchInTitleTextChanged(event: any) {
        props.filterTextChanged(event.target.value);
    }
    function clearButtonClicked() {
        // setFilterText('');
        props.filterTextChanged('');
    }

    function handleKeywordsChanged(keywords: Array<string>) {
        props.keywordsChanged(keywords);
    }

    function keywordTextFieldChanged(event: any) {
        // console.log('keywordTextFieldChanged ', event.target.value);
        // setCurrentlyEnteredNewKeyword(event.target.value);
        // if (event.key === 'Enter') {
        //     console.log('enter prezxxed')
        // }
    }

    function onKeywordTextFieldKeyPress(e: any) {
        // console.log('keyword text field key press', e.target.value, e.Key);
        // // // prevent form being submitted when return is pressed in the text field
        // // if (e.key === 'Enter') {
        // //     //console.log('onKeywordTextFieldKeyPress, enter pressed')
        // //     addNewKeyword();
        // // }
    }

    function onKeywordAutocompleteChange(event: any, value: string, reason: string) {
        // console.log('onKeywordAutocompleteChange', event.target.value, value, reason);
        if (reason === 'reset') {
            setCurrentlyEnteredNewKeyword(value);
        }
    }

    function onKeywordMenuChange(event: any) {
        if (event.target.value != null) {
            setCurrentlyEnteredNewKeyword(event.target.value);
        };
    }

    function addKeywordButtonClicked() {
        addNewKeyword();
    }

    function addNewKeyword() {
        if (
            !stringIsEmptyOrNull(currentlyEnteredNewKeyword) &&
            keywordStringsForTrack.indexOf(currentlyEnteredNewKeyword) === -1) {
            const _keywordStringsForTrack = [...keywordStringsForTrack];
            _keywordStringsForTrack.push(currentlyEnteredNewKeyword);
            setKeywordStringsForTrack(_keywordStringsForTrack);
        };
    }

    function handleRemoveKeywordAtIndex(index: number) {
        const _keywordStringsForTrack = [...keywordStringsForTrack];
        _keywordStringsForTrack.splice(index, 1);
        setKeywordStringsForTrack(_keywordStringsForTrack);
    }

    let keywordMenu: any = null;
    if (props.compact) {
        keywordMenu = (<FormControl style={{width: '250px'}}>
                <InputLabel htmlFor="keyword-select">Keyword</InputLabel>
                <NativeSelect onChange={onKeywordMenuChange} 
                inputProps={{
                    name: 'keyword',
                    id: 'keyword-chooser',
                  }}>
                      <option value={undefined}></option>
                    {allKeywordStrings.map(k => <option value={k}>{k}</option>)}
                </NativeSelect>
            </FormControl>);
    } else {
        keywordMenu = (<Autocomplete className={classes.searchForKeyword}
            id="choose-keyword-combo-box"
            options={allKeywordStrings}
            style={{ width: 240 }}
            onInputChange={onKeywordAutocompleteChange}
            renderInput={(params) => <TextField {...params} label="Search for Keyword" variant="outlined" size="small" onChange={keywordTextFieldChanged}
            onKeyPress={onKeywordTextFieldKeyPress} />}
            />);
    };

    return (<div>
        <div className={classes.textAndKeywordSearchWrapper + " " + (props.compact ? "flex-column-start-start" : "flex-row-start-center")}>
            {!props.compact ? (<div id="track-filter-params-wrapper" className="flex-row-start-center">
                <TextField className={classes.searchTextField}
                    margin="dense"
                    id="search-in-title" 
                    label="Search in Title" 
                    size="small"
                    variant="outlined"
                    value={props.filterText} 
                    onChange={searchInTitleTextChanged} />
                <IconButton id="clear-search-button" 
                    onClick={clearButtonClicked}>
                    <ClearIcon />
                </IconButton>
            </div>) : null}
            <div className="flex-row-start-center">
                {keywordMenu}
                <IconButton id="clear-search-button" 
                    onClick={addKeywordButtonClicked}>
                    <AddIcon />
                </IconButton>
            </div>
        </div>

        <div>
            <ChipGroup 
                chipNames={keywordStringsForTrack} 
                canClick={false}
                canDelete={true} 
                chipDeletedAtIndex={handleRemoveKeywordAtIndex}/>
        </div>
    </div>);
}

import React, {useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

interface ExclusiveButtonGroupProps {
    optionNames: Array<string>;
    optionIcons?: Array<any>;
    useIcons: boolean;
    selectedIndex: number;
    buttonSize: "small" | "medium" | "large";
    optionWasSelected: (selectedIndex: number) => any
}

export default function ExclusiveButtonGroup(props:  ExclusiveButtonGroupProps) {

    function buttonWasClicked(index: number) {
        props.optionWasSelected(index);
    }
    let buttonGroup = null;
    if (props.useIcons && props.optionIcons != null) {
        buttonGroup = (<ButtonGroup>
            {props.optionIcons.map((icon: any, index: number) => {
                const variant = index === props.selectedIndex ? "contained" : "outlined";
                return (<Button style={{width: 54}} key={index} color="primary" variant={variant} size={props.buttonSize} disableElevation onClick={() => {buttonWasClicked(index)}}>{icon}</Button>)
            })}
        </ButtonGroup>);
    } else {
        buttonGroup = (<ButtonGroup>
            {props.optionNames.map((name: string, index: number) => {
                const variant = index === props.selectedIndex ? "contained" : "outlined";
                return (<Button key={index} color="primary" variant={variant} size={props.buttonSize} disableElevation onClick={() => {buttonWasClicked(index)}}>{name}</Button>)
            })}
        </ButtonGroup>);
    }
    return (<div>{buttonGroup}</div>)
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../css/generic.css';
import '../css/flex.css';

const useStyles = makeStyles({
    linkImg: {
        width: '80px',
        marginRight: '30px'
    },
    storeWrapper: {
        marginTop: '20px'
    },
    linkWrapper: {
        marginTop: '10px'
    }
});

export default function AboutCylobIndustriesComponent() {
    const classes = useStyles();
    function getImgSrcForNetHostWithName(netHostName: string): string {
        return process.env.PUBLIC_URL + 'images/nethosts/' + netHostName + '.jpg';
    }

    const linkDetails: any = [
        {displayName: "Bandcamp", link: "https://cylobindustries.bandcamp.com/", imgSrc: getImgSrcForNetHostWithName('bandcamp')},
        {displayName: "Boomkat", link: "https://boomkat.com/labels/cylob-industries/", imgSrc: getImgSrcForNetHostWithName('boomkat')},
        {displayName: "Kudos Records", link: "https://cylobindustries.kudosrecords.co.uk/", imgSrc: getImgSrcForNetHostWithName('kudos')}
    ]

    return (<div className="material-font-family">
        The digital music label <strong>Cylob Industries</strong> was set up in 2007 exclusively in order to release the music of Cylob.<br/> <br/>
        It has so far released 10 albums and 3 singles of new or previously unreleased material, including under the alter-egos <strong>Ambient News</strong> and <strong>nonprivate</strong>. In addition, the label has reissued most of the Rephlex material.<br/> <br/>
        <div className={classes.storeWrapper}>
            <div>View Cylob Industries back catalogue on:</div>

            {linkDetails.map((details: any) => {
                return (<div className={classes.linkWrapper}>
                    <a href={details.link} target="_blank">
                        <div className="flex-row-start-center">
                            <img className={classes.linkImg + " release-image-small"} src={details.imgSrc}></img>
                            <div>{details.displayName}</div>
                        </div>
                    </a>
                </div>)
            })}
        </div>
    </div>)
}
